@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --black: #000;
  --white: #fff;
  --white1: rgba(255, 255, 255, 0.1);
  --white2: rgba(255, 255, 255, 0.2);
  --white3: rgba(255, 255, 255, 0.3);
  --white4: rgba(255, 255, 255, 0.4);
  --white5: rgba(255, 255, 255, 0.5);
  --white6: rgba(255, 255, 255, 0.6);
  --white7: rgba(255, 255, 255, 0.7);
  --white8: rgba(255, 255, 255, 0.8);
  --white9: rgba(255, 255, 255, 0.9);
  --yellowNVL: #fcb746;
  --purpleNVL: #5048e2;
  --zinc-50: #fafafa;
  --zinc-100: #f4f4f5;
  --zinc-200: #e4e4e7;
  --zinc-300: #d4d4d8;
  --zinc-400: #a1a1aa;
  --zinc-500: #71717a;
  --zinc-600: #52525b;
  --zinc-700: #3f3f46;
  --zinc-800: #27272a;
  --zinc-900: #18181b;
  --zinc-950: #09090b;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
button {
  cursor: pointer;
}
.box {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 100px;
  @media only screen and (max-width: 950px) {
    padding: 0 30px;
  }
}
.pricing-page {
  width: 100%;
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
  .pricing-top {
    width: 100%;
    background-color: var(--zinc-950);
    padding: 50px 0 100px 0;
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      width: 1000px;
      height: 1000px;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 50%;
      transform: translate(-50%, 50%);
      filter: blur(100px);
      background: radial-gradient(
        rgba(252, 183, 70, 0.25),
        rgba(252, 183, 70, 0)
      );
      z-index: 1;
    }
    &::after {
      content: "";
      width: 1000px;
      height: 1000px;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 50%;
      transform: translate(50%, -50%);
      filter: blur(100px);
      background: radial-gradient(rgba(80, 72, 226, 0.5), rgba(80, 72, 226, 0));
      z-index: 1;
    }
    .pt-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      z-index: 10;
      & > img {
        width: max-content;
        height: 65px;
      }
      h1 {
        font-size: 40px;
        font-weight: 500;
        color: var(--zinc-200);
        margin: 50px 0 10px 0;
        text-align: center;
        background: linear-gradient(100deg, var(--purpleNVL), #7f83e9, #a4aaf0);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @media only screen and (max-width: 1040px) {
          font-size: 32px;
        }
        @media only screen and (max-width: 850px) {
          font-size: 28px;
        }
      }
      h2 {
        font-size: 20px;
        font-weight: 400;
        color: var(--zinc-400);
        @media only screen and (max-width: 850px) {
          font-size: 17px;
          text-align: center;
        }
      }
      .pt-price-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .annual-monthly-box {
          width: max-content;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid var(--zinc-800);
          border-radius: 5px;
          // overflow: hidden;
          margin: 55px 0 135px 0;
          span {
            width: 100px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            color: var(--zinc-400);
            cursor: pointer;
            border-radius: 5px;
            transition: 0.35s;
            position: relative;
            i {
              display: flex;
              font-size: 10px;
              background-color: var(--white);
              padding: 2px 8px;
              white-space: nowrap;
              position: absolute;
              border-radius: 100px;
              font-style: normal;
              font-weight: 600;
              color: var(--purpleNVL);
              top: 0;
              left: 50%;
              transform: translate(-50%, -65%);
            }
            &.active {
              background-color: var(--purpleNVL);
              transition: 0.35s;
              color: var(--white);
            }
            &:hover {
              color: var(--white);
            }
          }
        }
        .pt-grid {
          width: 100%;
          grid-gap: 0;
          max-width: 1100px;
          margin: 0 auto;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-gap: 0;
          @media only screen and (max-width: 850px) {
            grid-template-columns: 1fr;
            grid-gap: 50px;
          }
          .pt-grid-card {
            width: 100%;
            height: max-content;
            background-color: hsla(0, 0%, 100%, 0.05);
            backdrop-filter: blur(3px);
            border: 1px solid var(--zinc-900);
            box-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
            position: relative;
            z-index: 5;
            padding: 65px 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            button {
              margin: 50px 0 0 0;
            }
            .annual-off {
              width: max-content;
              padding: 5px 15px;
              font-size: 15px;
              background-color: var(--white);
              color: var(--purpleNVL);
              font-weight: 600;
              border-radius: 1000px;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            h4 {
              font-size: 25px;
              font-weight: 600;
              color: var(--zinc-300);
              text-transform: uppercase;
              text-align: center;
            }
            p {
              font-size: 15px;
              color: var(--zinc-400);
              text-align: center;
              span {
                color: var(--zinc-500);
              }
            }
            h3 {
              font-size: 50px;
              font-weight: 600;
              color: var(--zinc-200);
              text-transform: uppercase;
              text-align: center;
              margin: 50px 0 0 0;
              @media only screen and (max-width: 1250px) {
                font-size: 36px;
              }
              span {
                font-size: 15px;
                font-weight: 400;
                color: var(--zinc-200);
                @media only screen and (max-width: 1250px) {
                  font-size: 13px;
                }
              }
            }

            &.starter {
              border-radius: 25px 0 0 25px;
              @media only screen and (max-width: 850px) {
                border-radius: 15px;
              }
            }
            &.pro {
              border-radius: 0 25px 25px 0;
              @media only screen and (max-width: 850px) {
                border-radius: 15px;
              }
            }
            &.unlimited {
              border-radius: 25px;
              padding: 0;
              background-color: var(--zinc-100);
              z-index: 6;
              height: max-content;
              transform: translate(0, -50px);
              @media only screen and (max-width: 850px) {
                transform: unset;
                border-radius: 15px;
              }
              .unlimited-content {
                width: 100%;
                padding: 50px 35px 35px 35px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                & > i {
                  font-style: normal;
                  font-size: 12px;
                  color: var(--zinc-500);
                  padding: 25px 0 0 0;
                  display: flex;
                }
              }

              h4 {
                color: var(--zinc-800);
                background: linear-gradient(
                  100deg,
                  var(--purpleNVL),
                  #7f83e9,
                  var(--yellowNVL)
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              p {
                color: var(--zinc-700);
              }
              h3 {
                color: var(--zinc-700);
                span {
                  color: var(--zinc-600);
                }
                i {
                  color: var(--zinc-400);
                  font-size: 25px;
                  font-weight: 500;
                  font-style: normal;
                  text-decoration: line-through;
                  @media only screen and (max-width: 1250px) {
                    font-size: 18px;
                  }
                }
              }
              button {
                margin: 50px 0 0 0;
              }
              img {
                position: absolute;
                width: 100px;
                top: -35.5px;
                right: -35.5px;
                rotate: 15deg;
                @media only screen and (max-width: 850px) {
                  right: -7.5px;
                }
              }
              .countdown {
                width: 100%;
                margin: 0;
                background-color: var(--zinc-800);
                padding: 35px 25px 25px 25px;
                border-radius: 25px 25px 0 0;
                background: linear-gradient(
                  100deg,
                  var(--purpleNVL),
                  #7f83e9,
                  var(--yellowNVL)
                );
                @media only screen and (max-width: 850px) {
                  border-radius: 15px;
                }
                h6 {
                  font-size: 13.5px;
                  text-transform: uppercase;
                  font-weight: 500;
                  color: var(--white);
                  margin: 0 0 5px 0;
                }
                .countdown-box {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  & > span {
                    font-size: 25px;
                    font-weight: 400;
                    color: var(--white);
                  }
                  h5 {
                    display: flex;
                    align-items: baseline;
                    justify-content: center;
                    text-align: center;
                    font-size: 22.5px;
                    font-weight: 600;
                    color: var(--white);
                    margin: 0;
                    span {
                      font-size: 10px;
                      font-weight: 400;
                      letter-spacing: 1px;
                      text-transform: uppercase;
                      color: var(--white);
                      margin: 0 0 0 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .features-grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 15px;
        padding: 75px 0 0 0;
        max-width: 1100px;
        margin: 0 auto;
        @media only screen and (max-width: 1050px) {
          grid-template-columns: 1fr 1fr;
        }
        @media only screen and (max-width: 600px) {
          grid-template-columns: 1fr;
        }
        .featured-card {
          width: 100%;
          padding: 20px 25px;
          border-radius: 10px;
          background-color: rgba(255, 255, 255, 0.25);
          backdrop-filter: blur(5px);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          img {
            width: 35px;
            object-fit: contain;
            margin: 0 10px 0 0;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            color: var(--white);
          }
        }
      }
    }
  }
  .comparison-section {
    width: 100%;
    padding: 75px 0;

    h1 {
      font-size: 40px;
      font-weight: 500;
      color: var(--zinc-200);
      margin: 0 0 50px 0;
      background: linear-gradient(100deg, var(--purpleNVL), #7f83e9, #a4aaf0);
      background-clip: text;
      -webkit-background-clip: text;
      text-align: center;
      -webkit-text-fill-color: transparent;
      @media only screen and (max-width: 1040px) {
        font-size: 32px;
      }
      @media only screen and (max-width: 850px) {
        font-size: 28px;
      }
    }
    .comparison-box {
      width: 100%;
      border-radius: 10px;
      overflow-x: hidden;
      .comparison-grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 0;
        @media only screen and (max-width: 850px) {
          grid-template-columns: 1fr;
          padding: 15px;
          background-color: var(--white);
        }

        .comparison-card {
          width: 100%;
          padding: 10px 20px;
          font-size: 16px;
          color: var(--zinc-600);
          border-top: 1px solid var(--zinc-200);
          &:first-child {
            font-weight: 500;
            @media only screen and (max-width: 850px) {
              border: unset;
            }
          }
          &:last-child {
            background-color: var(--purpleNVL);
            color: var(--white);
            border: 1px solid var(--white);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            @media only screen and (max-width: 850px) {
              background-color: unset;
              color: var(--zinc-600);
              box-shadow: none;
              border-top: 1px solid var(--zinc-200);
            }
          }
          span {
            display: none;
            @media only screen and (max-width: 850px) {
              display: block;
              font-weight: 600;
            }
          }
        }
        &.comparison-head {
          background-color: var(--zinc-800);
          @media only screen and (max-width: 850px) {
            display: none;
          }
          .comparison-card {
            color: var(--white);
            &:last-child {
              border-left: 0;
            }
          }
        }
        &:first-child .comparison-card:last-child {
          border-radius: 0 10px 0 0;
        }
      }
      &:last-child {
        &:nth-child(2) button,
        &:nth-child(3) button {
          color: var(--purpleNVL);
        }
      }
    }
  }
}
.btn-outlined-purple {
  width: 100%;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid var(--purpleNVL);
  background-color: transparent;
  color: var(--zinc-300);
  outline: 0;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
}
.btn-grd {
  border: 0;
  color: var(--white);
  background: linear-gradient(
    100deg,
    var(--purpleNVL),
    #7f83e9,
    var(--yellowNVL)
  );
}
.comparison-grid-unlimited .btn-unlimited {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
}
.comparison-grid-unlimited .btn-unlimited button {
  color: var(--white) !important;
}
