.nova-table-ctm .MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
}

.nova-table-ctm .MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.nova-table-ctm .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background-color: #cfcfcf;
  border: 2px solid #cfcfcf;
  border-radius: 10px;
}

.sidebar-main-parent + .CmtLayout-main  .CmtLayout-content {
  padding: 0 24px !important;
  margin-top: 0;
}

.MuiDataGrid-root .MuiTablePagination-spacer {
  display: none;
}

.MuiDataGrid-root .MuiToolbar-root.MuiToolbar-gutters {
  display: flex;
  padding: 0;
  min-height: 38px !important;
  padding-top: 12px;
  gap: 10px;
}
.MuiDataGrid-root .MuiDataGrid-footerContainer {
  display: block;
}

.MuiDataGrid-root .MuiTablePagination-displayedRows {
  order: 3 !important;
  text-align: right;
}

.MuiDataGrid-root .MuiInputBase-root.MuiInputBase-colorPrimary {
  margin: 0px;
}

.MuiDataGrid-root .MuiTablePagination-actions {
  margin: 0 !important;
  justify-content: center !important;
  flex-grow: 1;
  padding-right: 8%;
}

.MuiDataGrid-root ul.MuiPagination-ul {
  justify-content: center;
}
.MuiDataGrid-root .MuiDataGrid-footerContainer {
  display: block;
}
.MuiDataGrid-columnHeaders {
  background: #e0eafb;
}
.nova-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebecf3;
  padding-bottom: 10px;
  margin-bottom: 16px;
}

.camp-main .btn-grooups button:hover {
  background: #2C73FF !important;
  color: #fff !important;
}

.nova-table-ctm .MuiDataGrid-virtualScroller {
  max-height: calc(100vh - 335px) !important;
  overflow-y: auto;
  overflow-x: auto !important;
}
.nova-table-ctm span.MuiChip-label.MuiChip-labelMedium {
  text-transform: capitalize;
  font-family: 'DM Sans';
  padding: 10px 16px;
}
.nova-name-custom-div svg.add-chipIcon {
  border-radius: 25px !important;
  width: 55px !important;
  height: 26px !important;
}
/* .nova-box.library-box-only.library-messgae .nova-table-ctm .MuiDataGrid-virtualScroller {
  overflow-x: hidden !important;
} */
.nova-table-ctm .MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor {
  min-height: 36px;
}
.nova-table-ctm .MuiDataGrid-overlayWrapperInner .MuiDataGrid-overlay {
  background: #fff;
  height: calc(100% + 16px);
  width: calc(100% + 10px);
}
.nova-btn-card {
  text-align: end;
  width: max-content !important;
  margin-left: auto;
  position: relative;
  z-index: 1;
}
.CmtLayout-footer {
  display: none;
}
.nova-box {
  /* max-width: 900px; */
  margin: 0 auto;
}
.nova-container {
  background: #fff;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgb(8 15 52 / 1%);
  margin-bottom: 16px;
  min-height: calc(100vh - 202px);
}
div#loom-companion-mv3 {
  display: none;
}
.MuiDataGrid-main {
  border: 1px solid #ebecf3;
  border-radius: 10px;
}
.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
  color: #848d96;
}
.MuiSelect-select {
  color: #848d96 !important;
}
.MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row:nth-child(even) {
  background: #f6f6f6;
  max-height: 56px !important;
  min-height: 56px !important;
}
.MuiDataGrid-columnHeaderTitle {
  color: #170f49;
  font-weight: 500;
  font-size: 15px;
}
.nova-title > h2 {
  color: #170f49;
  font-weight: 500;
  margin: 0;
}
.nova-title > h2 {
  color: #170f49;
  font-weight: 500;
}
.nova-btn-card button {
  color: #fff;
  padding: 7px 20px;
  border-radius: 5px;
  letter-spacing: 0 !important;
  text-transform: capitalize;
  min-width: 105px;
  border: none !important;
  box-shadow: none !important;
  width: auto;
  display: inline-flex;
  align-items: center;
}
.nova-box>.MuiBox-root> button:hover {
  background: #14ab4c !important;
}
.nova-box-only.nova-box>.MuiBox-root> button.nova-red-btn:hover {
  background: #e90202 !important;
}
.nova-box-only.nova-box>.MuiBox-root> button.nova-red-btn-grp:hover{
  background: #7452aa !important;
 }
 .prospect-chip{
  cursor: pointer;
  border: white solid 1px !important;
  color: white !important;
}

.prospect-chip:hover{
  cursor: pointer;
  border: white solid 1px !important;
  color: white !important;
}
.library-keyword>.MuiBox-root button {
  min-width: 165px;
}
.nova-table-ctm .MuiDataGrid-cell.MuiDataGrid-withBorderColor {
  width: 0 !important;
  min-height: 56px !important;
  max-height: 56px !important;
}
.nova-table-ctm  .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor {
  min-height: 42px;
  max-height: 42px;
  line-height: 42px;
}
.nova-table-ctm  .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor {
  height: 42px;
}
.nova-table-ctm  .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor {
  height: 40px;
}
/* .nova-table-ctm .MuiDataGrid-virtualScrollerRenderZone {
  width: 100% !important;
} */

/* .nova-table-ctm .MuiDataGrid-row {
  width: 100% !important;
  display: grid !important;
  grid-template-columns: 1.35fr 1fr 1fr 10px;
} */

.MuiDataGrid-cell--textRight {
  width: 100% !important;
  min-width: 100% !important;
  display: flex !important;
  justify-content: flex-end !important;
}
.nova-table-ctm .MuiDataGrid-row button {
  border: none;
  letter-spacing: 0;
  text-transform: capitalize;
}
.nova-blue-btn {
  box-shadow: none;
  border-radius: 6px !important;
}
.nova-blue-btn:hover {
  background: #0b4ab7 !important;
}
.nova-green-btn {
  box-shadow: none !important;
}

.nova-name-custom-span {
  white-space: normal;
  line-height: 1.25;
  flex-grow: 1;
}

.nova-table-ctm .MuiDataGrid-cell.MuiDataGrid-cell--textLeft > div {
  width: 100%;
  /* justify-content: space-between; */
}

.nova-table-ctm .MuiDataGrid-cell:focus {
  outline: solid #2C73FF 0px !important;
}

.nova-table-ctm .MuiDataGrid-cell:focus {
  outline: solid #2C73FF 0px !important;
}

.nova-table-ctm .MuiDataGrid-row {
  max-height: 56px !important;
  min-height: 56px !important;
}
/* .nova-box .nova-table-ctm .MuiDataGrid-row {
  width: 100%;
} */
.nova-fb-icon {
  padding: 0 !important;
}

.nova-mutual-header div,
.nova-mutual-header div:hover,
.nova-gender-header div,
.nova-gender-header div:hover,
.nova-lives-header div,
.nova-lives-header div:hover {
  justify-content: flex-start;
}
.MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight {
  opacity: 0 !important;
}

.MuiDataGrid-cell[data-field="mutual_friend"],
.MuiDataGrid-cell[data-field="gender"],
.MuiDataGrid-cell[data-field="lived"] {
  text-align: center;
}
.MuiInputBase-adornedEnd {
  border: solid 0px #e0e1e3;
}

.MuiInput-underline.MuiInputBase-adornedStart::before {
  height: 36px !important;
  border-radius: 6px;
}
.nova-box-only .nova-table-ctm .MuiBox-root {
  width: max-content;
}
/* .nova--friends .nova-table-ctm .MuiBox-root,
.nova-deactivate .nova-table-ctm .MuiBox-root,
.nova-whitelist .nova-table-ctm .MuiBox-root {
  margin-top: -48px;
} */
/* .nova--friends .nova-btn-card.btn-group-friends-list, .nova-deactivate .nova-btn-card.btn-group-friends-list {
  margin-bottom: 10px;
} */
.nova-btn-card.btn-group-friends-list {
  margin-bottom: -40px;
}
.nova-table-ctm > .MuiBox-root {
  align-items: flex-end;
  padding-top: 0px;
}
/* .nova-btn-card.btn-group-friends-list {
  margin-bottom: -40px;
} */
.nova-container .MuiBox-root button {
  letter-spacing: 0;
  box-shadow: none;
  text-transform: capitalize;
  border-radius: 6px;
  font-weight: 500;
}
.library-tabs + h2 {
  font-weight: 500;
  color: #170f49;
}
button.MuiButtonBase-root.MuiButton-containedPrimary:hover {
  break-after: avoid;
  background: #2163e5;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12 {
  min-height: 320px;
}

.MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within {
  outline: solid rgba(44, 115, 255, 0.5) 0px !important;
  outline-width: 0 !important;
  outline-offset: 0px !important;
}

.MuiDataGrid-columnHeadersInner svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
  opacity: 1 !important;
}
.MuiDataGrid-columnHeadersInner .MuiDataGrid-iconButtonContainer {
  visibility: visible !important;
}

.nova-box.nova-box-only.nova--friends {
  position: relative;
}
.nova-box.nova-box-only {
  max-width: 2070px;
  margin: 0 auto;
}
.nova-table-activated-btn {
  position: absolute;
  width: 30%;
  height: calc(100% - 153px);
  background: linear-gradient(90deg, transparent 0%, #133068 7%,  #133068,  #133068,  #133068, #133068,  #133068, #133068);
  top: 105px;
  right: 5px;
  z-index: 22;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nova-table-ctm.nova-table-activated .MuiDataGrid-row::after {
  content: "";
  width: 60%;
  height: 100%;
  background: linear-gradient(90deg, transparent 0%, #133068 7%, #133068, #133068, #133068, #133068, #133068, #133068);
  right: -45%;
  top: 0;
  z-index: 11;
  position: absolute;
} 
.nova-table-activated .MuiDataGrid-columnHeadersInner {
  width: 100%;
}

.nova-table-activated .MuiDataGrid-columnHeadersInner>div {
  width: 100%;
}
.nova-table-activated  .MuiDataGrid-row {
  justify-content: space-between;
}
.nova-table-activated .MuiDataGrid-columnHeadersInner>div {
  width: 100%;
  justify-content: space-between;
}
.nova-table-activated-btn button {
  margin-left: 3%;
}
.nova-table-ctm span.MuiChip-label.MuiChip-labelMedium {
  text-transform: capitalize;
  font-family: 'DM Sans';
  padding: 10px 16px;
}


.nova-name-custom-div svg.add-chipIcon {
  border-radius: 25px !important;
  width: 55px !important;
  height: 26px !important;
}

.MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input {
  background: #f6f6f6;
  border: 1px solid #C4CCD2;
  border-radius: 6px;
  padding: 0 !important;
  min-width: 52px;

}

.MuiTablePagination-selectLabel {
  color: rgb(23 15 73 / 75%);
  font-family: 'DM Sans'  !important;
  font-weight: 500 !important;

}
.MuiTablePagination-root .MuiSelect-select {
  color: #170F49 !important;
  text-align: left !important;
  text-align-last: left !important;
  margin-top: 0px;
  padding: 5px 16px 5px 5px !important;

}

p.MuiTablePagination-displayedRows {
  color: rgb(23 15 73 / 75%);
  font-family: 'DM Sans';
  font-weight: 500;
}

.MuiPagination-ul button.MuiPaginationItem-root {
  border-radius: 6px;
  background: #f6f6f6;
  border: 1px solid #c4ccd2;
  color: rgb(23 15 73 / 85%);
  font-weight: 600;
  font-family: 'DM Sans';
}
.MuiDataGrid-virtualScroller .MuiDataGrid-overlayWrapper {
  min-height: 175px;
}

.nova-btn-card.btn-group-friends-list button {
  font-family: 'DM Sans' !important;
  border-radius: 8px;
}

/* _________ Updated css _________ */
/* .MuiDataGrid-virtualScroller .MuiDataGrid-row {
  width: 100% !important;
} */

.nova-table-ctm .MuiDataGrid-row:hover, .nova-table-ctm .MuiDataGrid-row.Mui-hovered {
  background-color: #f2f2f2 !important;
}
.nova-table-ctm .MuiDataGrid-virtualScroller>.MuiDataGrid-virtualScrollerContent:nth-child(even) {
  background: #f6f6f6 !important;
}
/* .nova-table-ctm .MuiDataGrid-virtualScroller>.MuiDataGrid-virtualScrollerContent:hover {
  background: #f6f6f6 !important;
}  */
.nova-unfriend .MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row,
.nova-deactivate .MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row,
.nova-whitelist .MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row  {
    width: 100%;
}
.nova-unfriend .MuiDataGrid-virtualScrollerRenderZone,
.nova-deactivate .MuiDataGrid-virtualScrollerRenderZone,
.nova-whitelist .MuiDataGrid-virtualScrollerRenderZone {
  width: 100% !important;
}



@media (min-width: 1400px) {
  .nova-table-ctm.nova-table-activated .MuiDataGrid-row::after {
     right: -35%;
  } 
}

@media (min-width: 1550px) {
  .nova-table-ctm.nova-table-activated .MuiDataGrid-row::after {
   right: -25%;
  } 
}

@media (min-width: 1600px) {
  .nova-table-activated-btn {
    width: 37%;
  }
  .nova-table-activated-btn button {
    transform: scale(1.35);
  }
}



@media (min-width: 1800px) {
  .nova-table-activated-btn {
    width: 40%;
  }
}

@media (min-width: 2000px) {
  .nova-table-activated-btn {
    width: 50%;
  }
}



@media (max-width: 1200px) {
  .nova-table-ctm .MuiDataGrid-virtualScroller {
    max-height: unset  !important;
    overflow-y: scroll;
  }
  
}

@media (max-width: 1023px) {
  .nova-btn-card.btn-group-friends-list {
    margin-bottom: 20px;
    overflow: auto;
    width: 100% !important;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .nova-btn-card.btn-group-friends-list>button {
    white-space: nowrap;
    width: calc(50% - 8px);
  }
  .nova-box-only .nova-table-ctm .MuiBox-root {
    width: 100% !important;
}
.nova-box-only .nova-table-ctm .MuiBox-root>div {
  width: 100% ;
}
}

@media (max-width: 767px) {
    .MuiDataGrid-root .MuiTablePagination-actions {
        padding-right: 0;
        order: 3;
    }
    .MuiDataGrid-root .MuiTablePagination-actions ul {
        justify-content: end;
    }
    .nova-table-ctm>.MuiBox-root .custom-search-class-nova {
      width: 100%;
    }
    .nova-table-ctm>.MuiBox-root {
        flex-wrap: wrap;
        gap: 6px;
    }
    .nova-table-ctm>.MuiBox-root .custom-search-class-nova + div button {
        padding: 6px;
        white-space: nowrap;
        letter-spacing: 0;
        text-transform: capitalize;
        flex-grow: 1;
    }
    .library-tabs + h2 {
        margin: 0px;
    }
    .nova-container {
      padding: 12px 15px;
    }
    .library-keyword .nova-table-ctm  .MuiDataGrid-cell.MuiDataGrid-withBorderColor {
      min-width: 200px!important;
    }
    .library-keyword .nova-table-ctm  .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor {
      height: 40px;
      min-width: 200px !important;
    }
    .nova-table-ctm .MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row:nth-child(even) {
      width: max-content !important;
    }
}