*{
	box-sizing:border-box;
    font-family: "DM Sans", sans-serif;
}
@font-face {
    font-family: "DM Sans", sans-serif;
    src: url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap);  
  }
  #root {
    overflow-x: hidden;
}
body {
    margin:0;
    padding:0;
    font-family: "DM Sans", sans-serif;
}
html, body {
    overflow-x: hidden;
}
.pl-container{
	max-width: 1140px;
	width: 100%;
	margin: 0 auto;
	padding:0 15px;
}
ul {
	padding:0;
}
li{
	list-style-type:none;
}
a{
	text-decoration: none;
}
.CmtLayout-main>.CmtLayout-content {
    padding: 0 !important;
}
header.plan-header, .pl-hero-section {
    transform: translateY(-10px);
}
.plan-header, .pl-footer {
    background: #f5f7fa;
    padding: 10px 0;
    padding-top: 25px;
    height: auto !important;
}
header, header + div.pl-hero-section {
    height: auto !important;
    background: #fff;
}
.plan-header-warp {
    display: flex;
    align-items: center;
    justify-content: space-between
}
.plan-header-warp img {
    max-width: 125px;
    width: 100%;
}
.pl-footer-txt {
    font-size: 14px;
    font-weight: 500;
    color: rgb(0 0 0 / 50%);
    text-align: center;
    margin: 5px;
}
.pl-section-main {
    padding-top: 100px;
}
.pl-lg-hd {
    font-size: 36px;
    color: #170F49;
    text-align: center;
    max-width: 750px;
    line-height: 1.15;
    margin: 30px auto 50px;
    text-transform: capitalize;
    border-left: 0;
    font-weight: 700;
    font-family: "DM Sans", sans-serif;

}
.pl-nova-image img {
    max-width: 730px;
    width: 100%;
    margin: 0 auto 15px;
    display: block;
}
.pl-top-btn-wrap {
    display: flex;
    position: relative;
}
.pl-top-btn-toggle {
    display: flex;
    gap: 6px;
    width: max-content;
    margin: 0 auto;
    border: 1px solid #cfcfcf;
    border-radius: 10px;
    padding: 5px;
}
.pl-toggle-btn input:checked + label {
    background: #2C73FF;
    color: #fff;
}
.pl-top-img {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(calc(-50% - 200px), -50%);
}
.pl-top-btn-wrap {
    display: flex;
    position: relative;
}
.pl-plans-btn label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    color: #2C73FF;
    font-size: 15px;
}
.pl-top-btn-toggle label, .pl-social-wrap label {
    background: rgb(44 115 255 / 10%);
    padding: 10px 30px;
    border-radius: 11px;
    display: flex;
    min-width: 115px;
    text-align: center;
    font-weight: 600;
    border: 1px solid #bed1f3;
}
.pl-social-wrap input:checked + label {
    background: #2c73ff;
    color: #fff;
}
.pl-social-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.pl-plans-btn-mid label {
    padding: 16px 32px;
    border-radius: 15px;
}
.pl-top-btn-wrap {
	padding: 35px 0;
}
.pl-section-main input[type="radio"] {
    display: none;
}
.pricing-table svg {
    width: 22px;
}
/*--table-css--*/
.table-inner .table-inner-div-01:nth-child(01) {
    justify-content: flex-start;
}
.table-inner {
    display: grid;
    align-items: center;
    background: rgb(44 115 255 / 9%);
    padding: 0 15px 0 25px;
    grid-template-columns: 31% 23% 23% 23%;
}
.table-hd-top {
    color: #2C73FF;
    font-weight: 600;
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
}
.table-inner.row-white {
    background: #fff;
}
.table-inner>div {
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 76px;
}

.table-inner>div:not(:last-child) {
    border-right: 1px solid #e2ebff;
}
.pricing-hd {
    font-size: 18px;
    font-weight: 600;
    color: #170F49;
    text-align: left;
    font-family: "DM Sans", sans-serif;
}
.pricing-table {
    font-size: 15px;
    color: #535353;
    font-weight: 600;
    font-family: "DM Sans", sans-serif;
}
.table-hd-btn {
    background: #2C73FF;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 700;
    width: 100%;
    max-width: 165px;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    font-family: "DM Sans", sans-serif;
}
.table-inner.table-inner-top {
    background: rgb(44 115 255 / 8%);
}
.table-wrap {
    margin: 35px 0;
    box-shadow: 0 3px 25px rgb(44 115 255 / 10%);
    border-radius: 8px;
    overflow: hidden;
}
.pl-packge-heading h3 {
    font-size: 40px;
    color: #170F49;
    font-weight: bold;
    text-align: center;
    margin: 0;
    font-family: "DM Sans", sans-serif;
}

.pl-package-hd-wrap {
    padding: 50px 0 30px;
}
/*--table-css-ends--*/
/*--discount-cards--*/
.starter-discont-cards.starter-mid-card {
    position: relative;
}
.pl-popular-card {
    background: linear-gradient(45deg, #2C73FF, #034FE5);
    padding: 10px 35px;
    border-radius: 50px 0px 0px 50px;
    position: absolute;
    right: 0;
    top: 30px;
}
.pl-popular-txt {
    color: #fff;
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    font-family: "DM Sans", sans-serif;
}
.plan-discount-cards {
    display: grid;
    grid-template-columns: 1fr 1.15fr 1fr;
    gap: 30px;
    align-items: center;
    max-width: 950px;
    width: 100%;
    margin: 60px auto 0;
}
.starter-discont-cards {
    border: 1px solid #efefef;
    border-radius: 38px;
    margin-top: 25px;
    box-shadow: 0 5px 35px rgb(0 0 0 / 10%);
    padding: 25px;
}
.card-lg-hd {
    font-size: 18px;
    font-weight: 700;
    font-weight: bold;
    color: #105cf5;
    margin-top: 10px;
    font-family: "DM Sans", sans-serif;

}
.card-sm-hd {
    font-size: 18px;
    font-weight: 500;
    color: rgb(23 15 73 / 70%);
    text-transform: capitalize;
    font-family: "DM Sans", sans-serif;
    letter-spacing: -.35px;
}
.starter-discont-cards:hover {
    border: 1px solid #2c73ff;
    box-shadow: 0 0 35px rgb(44 115 255 / 10%);
}
.card-discount-text {
    font-size: 60px;
    color: #2c73ff;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 10px;
    margin-top: 20px;
    border-left: 0;
    font-family: "DM Sans", sans-serif;
}
.small-year {
    font-size: 14px;
    font-family: "DM Sans", sans-serif;
}
.text-bold {
    font-weight: 700 !important;
    margin-bottom: 36px;
}
.card-chose-btn {
    background: #2C73FF;
    border: none;
    color: #fff;
    padding: 12px 15px;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 600;
    margin: 0 auto 10px;
    display: block;
    width: 100%;
    max-width: 100%;
    box-shadow: 0 5px 25px rgb(94 59 183 / 25%);
    font-family: "DM Sans", sans-serif;
}
.starter-mid-card .card-sm-hd {
    padding: 20px 0;
}
/*--discount-cards-end--*/
/*--simple-cards--*/
.plan-payment-security {
    background: rgb(44 115 255 / 7%);
    display: grid;
    grid-template-columns: repeat(6,1fr);
    border-radius: 25px 25px 0 0;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    gap: 1px;
}
.plan-security-nner {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px 20px 20px;
}
.plan-security-nner svg {
    width: 50px;
    height: 50px;
    transition: .1s;;
}
.plan-security {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    max-width: 140px;
    line-height: 1.35;
    font-family: "DM Sans", sans-serif;
}
.plan-security-nner:hover svg {
    transform: scale(1.2) translateY(-5px);
}

/*--simple-cards-end--*/
.trail-section-wrap {
    background: #f6f6f6;
    margin: 40px 0;
    padding: 50px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.trail-special-offer {
    font-size: 36px;
    color: #2C73FF;
    font-weight: 600;
    font-family: "DM Sans", sans-serif;
}
.trail-date {
    display: block;
    font-size: 19px;
    color: #2C73FF;
    font-weight: 600;
    font-family: "DM Sans", sans-serif;
}
.trail-section-wrap-top {
    display: flex;
    justify-content: center;
    gap: 55px;
    align-items: center;
}
.check-mark-green {
    width: 24px;
}
.trail-section-wrap-right {
    border: 4px solid #2C73FF;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    text-align: center;
    min-width: 200px;
    background: rgb(44 115 255 / 5%);
    padding: 25px;
}
.trail-num {
    font-size: clamp(56px, 7.5vw, 80px);
    font-weight: 900;
    color: #2c73ff;
    line-height: 1;
    font-family: "DM Sans", sans-serif;
}
.trail-num span {
    display: block;
}
.trail-num>span {
    font-size: 22px;
    font-weight: 600;
    font-family: "DM Sans", sans-serif;
}
.trail-start-text {
    font-size: 15px;
    font-weight: 600;
    background: linear-gradient(0deg, #e1d9f2, #efebf8);
    padding: 0 5px;
    transform: translateY(-10px);
    font-family: "DM Sans", sans-serif;
}
.trail-offer-text {
    font-size: 15px;
    font-weight: 600;
    background: linear-gradient(180deg, #e1d9f2, #efebf8);
    padding: 0 5px;
    transform: translateY(11px);
    font-family: "DM Sans", sans-serif;
}
.trail-section-wrap-left {
    padding-bottom: 15px;
}
#tiles {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
#tiles span {
    width: 140px;
    text-align: center;
        font-size: clamp(48px, 8vw, 80px);
    font-weight: 700;
    color: #5E3BB7;
    background: rgb(94 59 183 / 15%);
    border-radius: 8px;
    letter-spacing: 0px;
    display: flex;
        justify-content: center;
        font-family: 'Digital-7';
        line-height: 1.2;
}
#countdown {
    margin: 45px 0 0;
}

div#tiles {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Digital-7 Mono';
}

div#tiles span {
    width: 140px;
    text-align: center;
        font-size: clamp(48px, 8vw, 80px);
    font-weight: 700;
    color: #2c73ff;
    background: rgb(44 115 255 / 10%);
    border-radius: 8px;
    letter-spacing: 20px;
    display: flex;
        justify-content: center;
    padding-left: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-family: 'Digital-7';

}

div#countdown {
    margin: 45px 0 0;
}

.trail-section-wrap .labels {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.trail-section-wrap .labels li {
    width: 140px;
    text-align: center;
    color: rgb(23 15 73 / 65%);
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 10px;
}

.table-wrap .table-inner.row-white:last-child {
    border-top: 1px solid #e2ebff;
}
.checkmark-icons {
    width: 26px;
}

@media(min-width:992px){
.plan-security-nner:hover {
    background: linear-gradient(180deg, #1a5ee3, #2C73FF);
    /* border-radius: 26px 26px 0 0;
    transform: scale(1.15);
    transform-origin: bottom center; */
}
.plan-security-nner {
    position: relative;
}

.plan-security-nner::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 30px;
    background: #2763d9;
    opacity: 0;
    bottom: 100%;
    border-radius: 35px 35px 0 0;
}

.plan-security-nner:hover::before {
    opacity: 1;
}
.plan-security-nner:hover svg circle {
    fill: #fff !important;
    fillOpacity: 1;
}
.plan-security-nner:hover p {
    color: #fff;
}
}

@media(max-width:991px){
.pl-packge-heading h3 {
    font-size: 34px;
}
.plan-discount-cards {
    grid-template-columns: 1fr 1fr;
}
.plan-payment-security {
    grid-template-columns: repeat(2,1fr);
    margin: 50px -20px;
    border-radius: 0;
    padding: 20px 20px 36px;
}
}

@media(max-width:767px){
	.pl-package-hd-wrap {
        padding: 15px 0;
    }
	.plan-discount-cards {
	    grid-template-columns: 1fr;
	    max-width: 320px;
	    gap: 20px;
	}
	.starter-discont-cards {
	    max-width: 275px;
	    width: 100%;
	    margin: 0 auto;
	}
	.starter-discont-cards.starter-mid-card {
	    max-width: 100%;
	    width: 100%;
	}
	.pl-lg-hd {
	    font-size: 24px;
        margin: 5px 0;
	}
    .pl-section-main {
        padding-top: 50px;
    }
	.pl-top-btn-toggle label, .pl-social-wrap label {
	    align-items: center;
	    justify-content: center;
	}
	.pl-social-wrap {
	    flex-direction: column;
	}
	.table-wrap {
	    overflow-x: auto;
	    margin: 36px 0;
	}
	.pl-top-btn-toggle label, .pl-social-wrap label {
	    padding: 10px 16px;
	    min-width: 80px;
	    font-size: 14px;
	}
	.pl-top-img {
	    transform: translate(calc(-50% - 132px), -50%);
	}
	.pl-top-img img {
	    max-width: 87px;
	}
	.pl-social-wrap label {
	    min-width: 200px;
	}
	.table-hd-btn {	
	    padding: 10px 10px;
	    font-size: 13px;
	    width: 100%;
	    max-width: 135px;
	}
	.table-inner {
	    width: 720px;
	}
	.table-hd-top {
	    font-size: 15px;
	    max-width: 120px;
	}
	.table-inner>div {
	    padding: 0 5px;
	}
	.table-inner {
	    padding: 0 10px 0 10px;
	}
    .trail-section-wrap-top {
        gap: 15px;
        flex-direction: column;
    }
    div#tiles {
        gap: 8px;
    }
    div#tiles span {
        width: 75px;
        min-height: 85px;
        letter-spacing: 5px;
        padding-left: 0;
        align-items: center;
    }
    .trail-section-wrap .labels {
        gap: 8px;
    }
    .trail-section-wrap .labels li {
        font-size: 14px;
        width: 75px;
    }

}