* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
}
.container-ctm {
  width: 100%;
  margin: 0 auto;
}
.top-header {
  width: 100%;
  margin-top: 3px;
  padding: 16px 0 6px;
}
header,
header + div {
  height: 64px !important;
}

header > div {
  min-height: 50px !important;
}
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
  padding: 0;
}
.top-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  padding: 0 ;
  /* box-shadow: 0 4px 12px rgb(0 0 0 / 7%); */
}
.header-left-text {
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 200px;
}
.header-theme-mode {
  display: flex;
  align-items: center;
  gap: 12px;
}
.header-theme-mode span {
  font-size: 16px;
  color: #170f49;
  font-weight: 500;
}
.header-wel-text {
  font-size: 22px;
  color: #170f49;
  font-weight: 600;
  font-family: 'DM Sans' !important;
}
.header-wel-user {
  font-weight: 700;
}
.header-msg-cta,
.header-noti-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 1px solid #e7eaee;
  border-radius: 50%;
  position: relative;
}
.header-user {
  position: relative;
}
.header-user-a img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.user-online {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #61e86f;
  border-radius: 50%;
  top: 0;
  right: -2px;
  border: 1px solid #fff;
}
.header-language {
  border: 1px solid #e9ebef;
  min-height: 36px;
  border-radius: 8px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  min-width: 135px;
}
.header-language select {
  border: none;
  outline: none;
  width: 100%;
  color: #170f49;
  font-size: 16px;
  font-weight: 500;
  font-family: "DM Sans";
  padding: 0;
}
.header-language select:focus {
  outline: none;
  border: none;
}
.header-msg-cta::after,
.header-noti-cta::after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background: #61e86f;
  border-radius: 50%;
  outline: 1px solid #fff;
  right: 0px;
  top: -2px;
}
.header-noti-cta::after {
  background: #ec4134;
}

.header-theme-mode input.switch {
  position: relative;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 50px !important;
  height: 26px;
  border-radius: 30px;
  cursor: pointer;
  background: #fff;
  transition: box-shadow 0.3s ease-in-out, padding 0.25s ease-in-out;
  transition-delay: 0.1s, 0s;
  margin: 0;
  border: 1px solid #e6e6e6 !important;
}
.header-theme-mode input.switch:focus {
  border: 0;
  outline: 0;
}
.header-theme-mode input.switch:checked {
  transition-delay: 0s, 0s;
  box-shadow: inset 0 0 0 2.62857em #f6f6f6 !important;
  padding-left: 23px;
}
.header-theme-mode input:checked.switch:before,
input.switch:checked:after {
  background: #1cc067;
}
.header-theme-mode input.switch:before {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 2px;
  background-color: #cbcbcb;
  border-radius: 2.1em;
  transition: all 0.1s ease 0.1s;
}
.header-theme-mode input.switch:before,
input.switch:after {
  content: "";
  display: block;
}
.header-theme-mode input.switch:active::before {
  transition: all 0.1s ease 0.05s;
}
.header-theme-action {
  margin-top: 5px;
}
.header-left-text .MuiSelect-select {
  justify-content: flex-start !important;
  gap: 5px;
  padding: 6px !important;
}
ul.MuiMenu-list[aria-labelledby="select-locale"] {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 2px;
}

ul.MuiMenu-list[aria-labelledby="select-locale"] li {
  padding: 6px;
}
ul.MuiMenu-list[aria-labelledby="select-locale"] li img {
  width: 36px !important;
  height: 24px !important;
  border-radius: 5px !important;
  margin-right: 5px !important;
}
div#customizer-select-locale img {
  width: 36px !important;
  height: 24px !important;
  border-radius: 5px !important;
}
header.instagram-bar {
  background: #d357fe;
  height: auto !important;
  padding: 4px 0 !important;
}

.header.instagram-bar .MuiTypography-root {
  margin: 0 !important;
}

.instagram-bar-inner {
  padding: 5px 15px;
}

header.instagram-bar > div {
  min-height: 30px  !important;
  padding: 0px 15px;
}

.instagram-bar a {
  background: #2c73ff;
  color: #fff;
  font-size: 12px;
  padding: 5px 15px;
  display: none;
}
.instagram-bar-inner .MuiTypography-root.MuiTypography-h3 {
  color: #fff;
  font-size: 14px;
  margin: 0;
}

.signup-card .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2>div {
  width: 50% !important;
  max-width: 100% !important;
  flex-basis: 48%;
  padding-left: 14px !important;
}

.signup-card .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 {
  gap: 0;
  margin: 0;
}

.signup-card .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12 {
  min-height: unset;
}

@media (max-width: 1200px) {
  .top-header-inner {
    padding: 0 20px;
}
}


@media (max-width: 1023px) {
  .header-message,
  .header-noti {
    display: none;
  }
}

@media (max-width: 767px) {
  .top-header-inner {
    padding: 0 20px;
    flex-direction: column;
    gap: 16px;
  }
  .header-wel-text {
    line-height: 1.25;
    text-align: center;
  }
  .manage-request-left>div {
    flex-wrap: wrap;
    gap: 8px !important;
  }
}
