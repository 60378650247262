@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

:root {
  --black: #000;
  --white: #fff;
  --zinc-50: #fafafa;
  --zinc-100: #f4f4f5;
  --zinc-200: #e4e4e7;
  --zinc-300: #d4d4d8;
  --zinc-400: #a1a1aa;
  --zinc-500: #71717a;
  --zinc-600: #52525b;
  --zinc-700: #3f3f46;
  --zinc-800: #27272a;
  --zinc-900: #18181b;
  --zinc-950: #09090b;
  --purpleNVL: #5048e2;
  --blue : #2C73FF;
  --light-blue : #E0EAFB;
  --light-green : #00b853;
  --green : #03A54C;
  --red : #ff2424;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'DM Sans';
}

.german-events {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 20px; */
  padding-top: 20px;
}

.ge-header-container {
  background-color: var(--white);
  padding: 10px 20px;
  width: 100%;
  /* text-align: center; */
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.ge-header {
  font-size: 2rem;
  font-weight: 600;
  color: var(--zinc-900);
}

.ge-main-con {
  width: 100%;
  /* max-width: 800px; */
  background-color: var(--white);
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin: 20px 0;
  overflow: hidden;
  padding: 20px;
}

.ge-box-con {
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 1fr; /* This sets a 3:1 ratio */
  gap: 16px; /* Optional: Adjusts the gap between the grid items */
}

.ge-box-item {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 20px;
}

.ge-offer-con {
  display: flex;
  flex-direction: column;
  background-color: var(--light-blue);
  border-radius: 15px;
  padding: 20px;
  justify-content: space-between;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1);
}

.ge-offer-con p{
  font-size: 14px;
  /* font-weight: 600; */
  color: var(--zinc-900);

}


.ge-box-header{
  display: flex;
  justify-content: space-between;
  background-color: var(--light-blue);
  padding: 0 10px;
  border-radius: 10px;
  align-items: start;
}
.ge-box-header div {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-evenly ;
  gap: 0;
  justify-items: center ;
  color: #fff;
}
.ge-image {
  width: 100%;
  /* height: auto; */
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1);
  margin: 20px 0px;
}
.ge-box-date{
  display: flex;
  flex-direction: column;
}
.ge-box-date span{
  font-size: 14px;
  color: var(--zinc-900);
}

.ge-box-date h3 {
  font-size: 16px;
  /* font-weight: 600; */
  color: var(--zinc-900);
  margin-top: 8px;
}

.ge-box-item p {
  font-size: 14px;
  color: var(--zinc-900);
  padding: 0 5px;
}

.ge-attendees{
  /* font-weight: 600; */
  margin-top: -10px ;
  margin-bottom: 10px ;
}

.ge-corp-header{
  font-size: 14px;
  font-weight: 600;
  color: var(--zinc-900);
}

.success-con {
  background-color: var(--red);
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 5px;
}
.ge-note {
  font-size: 14px;
  color: var(--zinc-900);
}

.ge-disclaimer {
  font-size: 12px;
  color: var(--zinc-900);
  /* margin-top: 10px; */
}

.ge-location-link {
    display: inline-block;
    margin-top: 24px;
    /* align-self: end; */
    color: var(--purpleNVL);
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    transition: color 0.3s ease;
}

.ge-location-link:hover {
    color: rgba(80, 72, 226, 0.85);
}

.ge-buy-btn {
  background-color: var(--blue);
  color: var(--white);
  padding: 10px 20px;
  width: 100%;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease;
  position: relative;
}

.ge-buy-btn:hover {
  background-color: rgba(44, 115, 255, 0.85);
}

.ge-progress{
  position: absolute;
  color: white !important;
}