.MuiInputBase-adornedEnd {
  border: solid 1px #e0e1e3;
  /* background-color: #dedaeb; */
  color: #8a8c8d;
  padding-right: 30px;
}

.MuiCardHeader-title {
  padding-left: 8rem;
  font-size: 60px;
}

.css-1qxrzhd-MuiButtonBase-root-MuiButton-root {
  background-color: black;
}
.library-tabs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  gap: 20%;
  margin-top: 3px;
}

.library-tabs > div {
  grid-template-columns: 1fr 1fr 1fr !important;
  margin: 0 !important;
  flex-grow: 1;
}
.library-tabs .top-header {
  margin-bottom: 10px !important;
}



@media (max-width: 767px) {
  .library-tabs .top-header {
    margin-bottom: 16px !important;
}
}