.tabmenu-btn {
  align-items: center;
  background: #2c73ff1a;
  border-radius: 8px;
  box-shadow: none!important;
  color: #637381;
  display: flex;
  font-size: 14px;
  white-space: nowrap;
  gap: 8px;
  letter-spacing: -.35px;
  justify-content: center;
  min-height: 44px;
  padding: 5px;
  width: 100%;
}
.tabmenu-btn svg {
  width: 24px;
}

.tabmenu-btn:hover {
  background: rgb(44 115 255 / 100%);
  color: #fff;
}

.tabmenu-btn:hover .only-stroke path {
  stroke: #fff;
}
.tabmenu-btn:hover .only-fill path {
  fill: #fff;
}

.tabmenu-btn.tab-menu-active {
  color: #fff;
  background: rgb(44 115 255 / 100%);
}

.tabmenu-btn.tab-menu-active .only-stroke path {
  stroke: #fff;
}
.tabmenu-btn.tab-menu-active .only-fill path {
  fill: #fff;
}


@media (min-width: 1650px) {
  .tabmenu-btn {
    font-size: 18px;
  }
  .sep-table-tabsInner .tabmenu-btn {
    width: auto !important;
    padding: 0 16px;
  }
}