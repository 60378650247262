* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
}
.container {
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
ul {
  padding: 0;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
}
/*--dashboard-css--*/
.affiliate-dashboard-hd {
  color: #170f49;
  font-size: 20px;
  font-weight: 700;
}
.affiliate-dashboard-video {
  display: flex;
  gap: 25px;
}
.affiliate-dash-video {
  max-width: 65%;
  width: 100%;
}
.become-an-affiliate {
  background: rgb(94 59 183 / 10%);
  border-radius: 12px;
  padding: 45px 15px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 35%;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.Become-Affiliate-btn {
  background: #5e3bb7;
  color: #fff;
  font-size: 18px;
  padding: 10px 30px;
  border-radius: 10px;
  max-width: 245px;
  width: 100%;
  margin: 0 auto;
  display: block;
  border: none;
  outline: 3px solid rgb(94 59 183 / 20%);
  position: relative;
  z-index: 99;
  cursor: pointer;
}
.Affiliate-aggrement {
  display: block;
  background: #c9c1ec;
  color: #5e3bb7;
  border: 1px solid rgb(94 59 183 / 50%);
  border-radius: 12px;
  padding: 10px 16px;
  max-width: 195px;
  width: 100%;
  font-size: 16px;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  position: relative;
  z-index: 99;
  cursor: pointer;
}
.affiliate-dash-video video {
  max-width: 690px;
  width: 100%;
  border-radius: 12px;
  height: 345px;
}
.Affiliate-salep {
  font-size: 14px;
  font-weight: 400;
  color: rgb(33 43 54 / 80%);
}
.become-an-affiliate::before {
  position: absolute;
  content: "";
  /* background-image: url(../images/affiliate-btn-img.png); */
  bottom: 0;
  right: -35px;
  width: 85%;
  height: 88%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}
.affiliate-dashboard-hd {
  color: #170f49;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
/*--dashboard-css-end---*/

/*--grid-view-css---*/
.aff-top-tabs {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 12px;
}
.aff-tabs {
  display: flex;
  align-items: center;
  background: rgb(94 59 183 / 10%);
  padding: 10px;
  gap: 10px;
  border-radius: 8px;
  font-size: 14px;
  color: rgb(99 115 129 / 90%);
  font-weight: 500;
  flex-grow: 1;
}
.aff-tabs svg {
  width: 24px;
}
.aff-tabs.aff-tabs-dash {
  background: #5e3bb7;
  color: rgb(255 255 255 / 90%);
}
.aff-top-filter {
  background: #5e3bb7;
  border: none;
  color: #fff;
  border-radius: 8px;
  padding: 10px 25px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
  outline: 1px solid rgb(94 59 183 / 20%);
}
.aff-tabs-top-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.aff-grid-view-inn {
  border: 1px solid rgb(94 59 183 / 15%);
  border-radius: 16px;
  padding: 12px 0;
}
.aff-grid-ryt-data {
  font-size: 14px;
  color: #170f49;
  font-weight: 600;
  margin: 5px 0;
}
.aff-grid-data,
.aff-grid-view-image {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}
.aff-grid-view-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.aff-grid-view-image img {
  border-radius: 10px;
  border: 1px solid rgb(94 59 183 / 20%);
}
.aff-grid-view-image {
  border-bottom: 1px solid rgb(94 59 183 / 15%);
  padding-bottom: 12px;
}
.aff-grid-left-data,
.aff-grid-profile {
  color: #5e3bb7;
  font-size: 14px;
  font-weight: 600;
}
.aff-button-paid {
  background: #22c55e;
  border: none;
  color: #fff;
  padding: 6px 10px;
  border-radius: 6px;
}
.aff-grid-data-top {
  padding-top: 10px;
}
.aff-button-paid.aff-button-continue {
  background: #ffc804;
}

.aff-button-paid.aff-button-payment {
  background: #ff7a00;
}
.aff-button-paid.aff-button-cancelled {
  background: #ff0000;
}
.aff-top-filter.aff-top-list {
  background: #22c55e;
}

/*--grid-view-css-end---*/

/*--dashboard-css---*/
.Lifetime.Earning-hd {
  color: rgb(23 15 73 / 75%);
  font-size: 16px;
  margin: 0;
  padding-bottom: 10px;
  font-weight: 600;
}
.aff-dashboard-eraning-wrap {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  padding-top: 25px;
  flex-wrap: wrap;
}

.aff-dashboard-earning.aff-End-Month {
  max-width: 26%;
}
.aff-End-Month-outer {
  display: flex;
  gap: 10px;
}
.aff-dashboard-earning {
  max-width: 23%;
  width: 100%;
  border-radius: 12px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aff-dashboard-lifetime {
  background: rgb(243 121 126 / 20%);
  border: 1px dashed rgb(243 121 126);
}

.aff-Last-Month {
  background: rgb(125 160 250 / 20%);
  border: 1px dashed #7da0fa;
}

.aff-Total-Payment {
  background: rgb(34 154 22 / 20%);
  border: 1px dashed #229a16;
}

.aff-End-Month {
  background: linear-gradient(45deg, rgb(84 82 245 / 16%), rgb(84 82 245 / 0%));
  position: relative;
  overflow: hidden;
}

.aff-dashboard-lifetime h2 {
  color: #f3797e;
}

.aff-Last-Month h2 {
  color: #4e7aeb;
}

.aff-Total-Payment h2 {
  color: #26b418;
}

.Lifetime.Earning-pay {
  margin: 0;
}
.aff-End-Month-hour h3,
.aff-End-Month-hour p {
  margin: 3px 0;
}

.aff-End-Month-hour p {
  color: #5e3bb7;
  font-size: 14px;
  font-weight: 500;
}

.aff-End-Month p {
  color: #170f49;
}
.aff-End-Month::before {
  position: absolute;
  content: "";
  /* background-image: url(../images/Illustration.png); */
  bottom: 0;
  right: 0px;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
}
.aff-Qualify-outer,
.aff-earning-wrap {
  box-shadow: 0 0 7px rgb(0 0 0 / 8%);
  border-radius: 12px;
  padding: 20px;
  background: #fff;
}
.aff-Qualify-outer {
  margin-top: 25px;
}
.aff-total-name {
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0;
  color: #151d48;
}

.aff-total-Sales-data {
  text-align: center;
  max-width: 9%;
}

.aff-total-Sales-wrap {
  display: flex;
  padding-top: 20px;
  gap: 10px;
  flex-wrap: wrap;
}
.aff-earning-hd {
  color: #151d48;
  font-size: 18px;
  margin: 0;
  font-weight: 600;
}
.aff-earning-outer {
  padding-top: 26px;
  display: grid;
  grid-template-columns: 30% 1fr;
  gap: 25px;
}
.aff-congratulation.aff-active {
  display: inline flex;
}
.aff-earning-level {
  display: flex;
  justify-content: space-between;
  background: #f9fafb;
  border: 1px solid rgb(145 158 171 / 24%);
  padding: 12px 16px;
  margin: 15px 0;
  border-radius: 8px;
}

.aff-earning-level-cont {
  font-size: 14px;
  font-weight: 500;
  color: rgb(33 43 54 / 80%);
  margin: 0;
}
.aff-dashpayout-wrap {
  display: flex;
  padding-top: 25px;
  gap: 25px;
  justify-content: space-between;
}

.aff-dashpayout {
  max-width: 32%;
  width: 100%;
}
.aff-earning-text {
  padding-top: 15px;
  line-height: 1.4;
}
.aff-dashpayout-inn {
  background: #f9fafb;
  border: 1px solid rgb(145 158 171 / 24%);
  padding: 8px 16px;
  margin: 10px 0 0;
  border-radius: 8px;
  text-align: center;
}
.aff-dashpayout h3.aff-earning-hd {
  text-align: center;
}
.aff-dashpayout img {
  margin: 25px auto 0;
  display: block;
  max-width: 120px;
  width: 100%;
}
.aff-earning-percentage {
  margin: 10px 0 0 0;
  color: #5e3bb7 !important;
  font-size: 18px;
  font-weight: 700;
}
.aff-earning-level.aff-earning-total {
  background: #e9fcd4;
  margin-bottom: 0px;
}
.aff-earning-level-total {
  color: #212b36;
  font-weight: 600;
}

.aff-level-total {
  color: #229a16;
  font-weight: 600;
}
.aff-dashpayout-inn p {
  color: #170f49;
}
.aff-Qualification-wrap {
  display: flex;
  gap: 20px;
  padding-bottom: 30px;
}
.aff-promoted-Gold {
  padding-top: 20px;
  border-top: 1px solid #d9d9d9;
}
.aff-Qualification-outer {
  max-width: 33%;
}

.aff-Qualification-outer.aff-Qualification-right {
  max-width: 66%;
}
.aff-btn-group-wraper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.aff-btn-group-wraper span {
  font-size: 18px;
  font-weight: 600;
}
.aff-btn-group-wraper button svg {
  width: 18px;
  height: 18px;
}
.aff-btn-group-wraper .aff-top-filter {
  padding: 10px 16px;
}

.aff-btn-group-wraper span span {
  font-size: 16px;
  font-weight: 400;
}
.aff-btn-group-wraper {
  display: flex;
  align-items: center;
  gap: 12px;
}
.aff-Qualification-card.aff-sliver {
  display: flex;
  gap: 20px;
}

.aff-Qualify-card.aff-gold {
  background: rgb(255 243 181 / 80%);
}

.aff-Qualify-card {
  border-radius: 12px;
  padding: 17px;
  text-align: center;
  position: relative;
}
.aff-congratulation-text {
  color: #26b418 !important;
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0 15px 0;
}
.aff-Qualify-hd {
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0;
}

.aff-congratulation {
  /* background-image: url(../images/silver-color-image.png),
    linear-gradient(180deg, #d9d9d900 26%, #dddddd 56%); */
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  display: none;
}
.aff-silver {
  background: #f2f2f2;
}
.aff-platinum {
  background: #dee7f6;
}
.aff-Qualification-outer h3 {
  padding-bottom: 15px;
}
.aff-platinum h2 {
  color: #0c3b86;
}

.aff-Qualify-card p {
  color: #273240;
}
.aff-congratulation img {
  max-width: 100%;
  width: 100%;
}
.aff-gold h2 {
  color: #bf8702;
}
.aff-promot-out-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.aff-promot-out-wrap img {
  background: red;
  border-radius: 50%;
  padding: 10px;
  width: 90px;
  height: 90px;
  object-fit: cover;
}
.aff-promoted-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
}
.aff-promot-bg-silver img {
  background: #f6f9f6;
}

.aff-promot-bg-gold img {
  background: #fffbec;
}

.aff-promot-bg-platinum img {
  background: #f5f6f8;
}
.aff-promot-hd {
  color: #656565;
  margin: 0;
  font-size: 26px;
  font-weight: 600;
}

.aff-promot-inn {
  color: rgb(51 51 51 / 60%);
  font-weight: 500;
  font-size: 16px;
  margin: 8px 0;
}

.aff-promot-inn span {
  color: #333;
}

.aff-promot-cont {
  text-align: center;
}

.aff-promot-outer {
  padding: 25px 18px 25px 18px;
}

.aff-promoted-wrap > div:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}
.aff-Personal-Active-wrap {
  padding-top: 20px;
}
.affiliate-bg-title {
  background: rgb(94 59 183 / 15%);
  font-size: 18px;
  font-weight: 700;
  padding: 10px 15px;
  border-radius: 12px 12px 0 0;
}
.affiliate-table-wraper table {
  width: 100%;
  border-radius: 0 0 12px 12px;
  border-collapse: collapse;
}
.affiliate-table-wraper table thead th {
  color: #5e3bb7;
  text-align: left;
  border-bottom: 1px solid #ebecf3;
}
.affiliate-table-wraper table thead th,
.affiliate-table-wraper table tbody td {
  padding: 8px 16px;
  font-size: 14px;
}
.affiliate-table-wraper table tbody tr:not(:last-child) td {
  border-width: 0 0 1px 0;
  border-color: #ebecf3;
  border-style: solid;
}
.affiliate-table-wraper {
  margin-top: 30px;
  margin-bottom: 16px;
  border: 1px solid #ebecf3;
  border-radius: 12px;
  overflow: hidden;
}
.affiliate-main-dash .affiliate-table-wraper table {
  border-radius: 12px;
}
.affiliate-main-dash .affiliate-table-wraper table thead th {
  background: rgb(94 59 183 / 10%);
}

/*--dashboard-css-end---*/

/*--Updated css 18/9/2024---*/
.sep-dash-sec.dash-sec {
  padding: 25px 0;
  justify-content: start;
}
.sep-dash-sec .novlay-dash-main-hd {
  font-size: 20px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: #170F49;
  letter-spacing: -.35px;
  line-height: 1.2;
  font-family: "DM Sans", sans-serif;
  margin-bottom: 16px;
}
.sep-dash-sec .novalya-dashboard-warp-left {
  display: grid;
  grid-template-columns: calc(65% - 24px)  35%;
  justify-content: space-between;
  align-items: end;
}
.sep-dash-sec .vide-wrap-nova>iframe {
  width: 100%;
  height: 100%;
}
.sep-dash-sec .vide-wrap-nova {
  border-radius: 16px;
  overflow: hidden;
  height: 375px;
}
.sep-dash-sec .novalya-dashboard-right {
  background: rgb(44 115 255 / 10%) !important;
  border-radius: 16px;
  min-height: 375px!important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sep-dash-sec .novalya-dashboard-rightactive {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.sep-dash-sec .novalya-rightactive-text {
  width: 100%;
  text-align: center;
}
.sep-dash-sec .novalya-rightactive-text>button {
  margin: 0 auto !important;
}
button.sep-lg-btn {
  background: #2C73FF !important;
  color: #fff;
  font-size: 18px !important;
  padding: 14px 40px;
  border-radius: 12px;
  width: max-content !important;
  letter-spacing: 0 !important;
  display: block;
  border: none !important;
  outline: 1px solid rgb(44 115 255 / 20%) !important;
  font-family: "DM Sans", sans-serif;
}
button.sep-sm-btn {
  background: #fff !important;
  color: #2C73FF !important;
  font-size: 18px !important;
  padding: 10px 24px;
  border-radius: 12px;
  max-width: 100%;
  width: max-content !important;
  letter-spacing: 0 !important;
  display: block;
  border: none !important;
  outline: 1px solid rgb(44 115 255 / 50%) !important;
  font-family: "DM Sans", sans-serif;
}
.sep-dash-sec .novalya-dashboard-warp-right {
  position: relative;
}
.sep-dash-sec .novalya-dashboard-warp-right>svg {
  position: absolute;
  bottom: 0;
  right: 0;
}
.sep-sales-bonus {
  padding: 0;
  background: transparent;
  padding: 24px 0 36px;
}
.sep-sales-bonus p {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 1.35;
  margin: 0 !important;
}
.grid-box.sep-actual-pay-table {
  padding: 0;
  border: 1px solid #EBECF3;
  border-radius: 16px;
  overflow: hidden;
}

.sep-actual-pay-table h3 {
  background: rgb(44 115 255 / 10%);
  color: #2C73FF;
  margin: 0;
  padding: 12px 30px;
  border-radius: 16px 16px 0 0;
}

.sep-actual-pay-table .NVL-table {border-radius: 0;}

.NVL-table th {
  background: #fff;
  color: #2C73FF;
  font-family: "DM Sans", sans-serif !important;
  border-left: none  !important;
  border-right: none  !important;
}
.NVL-table td {font-family: "DM Sans", sans-serif !important;}
.NVL-table tr, .NVL-table td {
  border-left: 0 !important;
  border-right: 0 !important;
}
.NVL-table td, .NVL-table th {
  border: 1px solid #EBECF3;
}


@media (min-width: 1600px) { 
  .sep-dash-sec .novalya-dashboard-main-warp {
    zoom: 1.25;
  }
}

@media (min-width: 1920px) { 
  .sep-dash-sec .novalya-dashboard-main-warp {
    zoom: 1.45;
  }
}


@media (min-width: 2500px) { 
  .sep-dash-sec .novalya-dashboard-main-warp {
    zoom: 1.65;
  }
}

@media (min-width: 3000px) { 
  .sep-dash-sec .novalya-dashboard-main-warp {
    zoom: 2;
  }
}
/*-----reponsive---*/

@media (max-width: 991px) {
  .aff-grid-view-wrap {
    grid-template-columns: 1fr;
  }
  .aff-dashboard-earning {
    max-width: 22%;
  }
  .aff-earning-outer {
    grid-template-columns: 1fr;
  }
  .aff-Qualification-wrap > div {
    max-width: 100% !important;
  }
  .aff-Qualification-wrap {
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .aff-dashboard-earning {
    max-width: 21%;
  }
}
@media (max-width: 767px) {
  .affiliate-dashboard-video {
    display: block;
  }
  .affiliate-dash-video,
  .affiliate-dash-video video {
    max-width: 100%;
  }
  .become-an-affiliate {
    max-width: 100%;
    margin-top: 25px;
  }
  .become-an-affiliate::before {
    right: -35px;
    width: 65%;
    height: 90%;
  }
  .aff-grid-view-wrap {
    grid-template-columns: 1fr;
  }
  .aff-grid-view-wrap {
    grid-template-columns: 1fr;
  }
  .aff-dashboard-earning.aff-End-Month,
  .aff-dashboard-earning {
    max-width: 48%;
  }
  .aff-Qualify-card,
  .aff-promot-outer {
    max-width: 100%;
    width: 100%;
  }
  .aff-promoted-wrap > div:not(:last-child) {
    border-right: 0;
  }
  .aff-total-Sales-data {
    max-width: 28%;
  }
  .aff-Qualification-card.aff-sliver {
    flex-wrap: wrap;
  }
}
