body {
    background-color: #F6F6F9 !important;
}
.blurred-div {
    filter: blur(3px); 
    pointer-events: none;
}
.sep-affiliateMenu {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 16px;
    margin-bottom: 16px;
}
.sep-affiliateMenu>div {
    flex-grow: 1;
    width: 20%;
}
.sep-affiliateMenu .tabmenu-btn>span:nth-child(01) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}
.sep-affiliateMenu .tabmenu-btn:hover svg path,
.sep-affiliateMenu .tabmenu-btn.tab-menu-active svg path {
    stroke: #fff;
}
.sep-affiliateMenu .tabmenu-btn svg {
    width: 20px;
    height: 20px;
}
.sep-affiliate-dashboard-cards {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr 1fr 1.35fr;
}
.sep-affiliate-dashboard-cardsInner {
    border-radius: 12px;
    padding: 20px;
    line-height: 32px;
    font-size: 16px;
    font-weight: 500;
    font-family: "DM Sans";
}
.bg-red.sep-affiliate-dashboard-cardsInner {
    border: 1px dashed rgb(243, 121, 126);
    background: rgba(243, 121, 126, 0.12);
}   
.bg-blue.sep-affiliate-dashboard-cardsInner {
    border: 1px dashed rgb(125, 160, 250);
    background: rgba(125, 160, 250, 0.12);
} 
.bg-green.sep-affiliate-dashboard-cardsInner {
    border: 1px dashed rgb(34, 154, 22);
    background: rgba(34, 154, 22, 0.12);
} 
.sep-affiliate-dashboard-cardsTimes {
    border: 1px dashed rgba(125, 160, 250, 1);
    border-radius: 12px;
    padding: 10px 15px 0;
    background: rgba(125, 160, 250, 0.2);
    line-height: 32px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 42%;
    text-align: center;
    padding-bottom: 0;
}
.affiliate-dashboard-topbar-value {
    font-size: clamp(24px, 2vw, 30px);
    line-height: 1.1;
    font-weight: 600;
    font-family: "DM Sans";
    margin-top: 10px;
    margin-bottom: 0px;
}
.bg-red .affiliate-dashboard-topbar-value {
    color: rgb(243, 121, 126);
}
.bg-blue .affiliate-dashboard-topbar-value {
    color: rgba(125, 160, 250, 1);
}
.bg-green .affiliate-dashboard-topbar-value {
    color: rgba(34, 154, 22, 1);
}
.affiliate-dashboard-topbar-title {
    line-height: 1;
    margin-top: 6px;
}
.sep-dashboard-earning {
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 110px;
}
.sep-dashboard-lifetime {
    background: rgb(243 121 126 / 12%);
    border: 1px dashed #F3797E;
}
.sep-Last-Month {
    background: rgb(125 160 250 / 12%);
    border: 1px dashed #7DA0FA;
}
.sep-Total-Payment {
    background: rgb(34 154 22 / 12%);
    border: 1px dashed #229A16;
}
.sep-End-Month {
    background: linear-gradient(45deg, rgb(84 82 245 / 16%), rgb(84 82 245 / 0%));
    position: relative;
    overflow: hidden;
    padding: 0px;
}

.sep-dashboard-lifetime h2 {
    color: #F3797E;
}

.sep-Last-Month h2 {
    color: #4e7aeb;
}

.sep-Total-Payment h2 {
    color: #26B418;
}
.sep-dashboard-topbar-title {
    color: rgb(23 15 73 / 80%);
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 5px;
}
.sep-earning-outer {
    padding-top: 26px;
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    gap: 20px;
    margin-bottom: 35px;
}
.sep-earning-wrap, .sep-right-earning-box {
    box-shadow: 0 7px 20px rgb(0 0 0 / 7%);
    border-radius: 12px;
    padding: 20px;
    background: #fff;
}
.sep-earning-hd {
    color: #170F49;
    font-size: 18px;
    margin: 0 0 10px;
    font-weight: 600;
    line-height: 1.25;
}
.sep-dashpayout-wrap .sep-earning-hd {
    text-align: center;
}
.sep-left-earning-level {
    display: flex;
    justify-content: space-between;
    background: #F9FAFB;
    border: 1px solid rgb(145 158 171 / 24%);
    padding: 12px 16px;
    margin: 20px 0;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
}
.sep-left-earning-level.sep-earning-total {
    background: #E9FCD4;
    margin-bottom: 0px;
}
.sep-reffer .novalya-rightactive-text {
    margin-top: 30px;
}
 .sep-reffer .sep-dashpayout-wrap {
    display: grid;
    padding-top: 20px;
    gap: 20px;
    grid-template-columns: 28%  36% 28%;
    justify-content: space-between;
    margin-bottom: 16px;
}
.sep-dashpayout-inn {
    background: rgb(44 115 255 / 5%);
    border: 1px solid rgb(44 115 255 / 20%);
    padding: 8px 16px;
    margin: 10px 0 0;
    border-radius: 8px;
    text-align: center;
}
.sep-earning-level-cont {
    font-size: 14px;
    font-weight: 500;
    color: rgb(33 43 54 / 80%);
    margin: 0;
    line-height: 1.35;
}
.sep-earning-percentage {
    color: #2C73FF!important;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.25;
    margin-top: 4px;
}
.sep-grid-box {
    border: 1px solid #EBECF3;
    background: #fff;
    border-radius: 16px;
    overflow: hidden;
}
.aff-table-head {
    color: #2C73FF;
    background: rgb(44 115 255 / 10%);
    font-size: 18px;
    font-weight: 500;
    padding: 12px 24px;
    border-radius: 16px 16px 0 0;
    margin: 0;
}
.sep-NVL-table1 table thead th {
    color: #1B64F3 !important;
    font-weight: 700 !important;
    font-family: 'DM Sans' !important;
    white-space: nowrap;
}
.sep-aff-table-hd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 35px;
    gap: 16px;
    flex-wrap: wrap;
}
.sep-aff-table-btn svg {
    width: 20px;
    height: 20px;
}
.sep-aff-table-btn {
    background: #2C73FF;
    border: none;
    color: #fff;
    font-family: 'DM Sans';
    padding: 10px 16px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    transition: .2s;
    cursor: pointer;
    justify-content: center;
}
.sep-aff-table-btn:hover {
    background: #1c61eb;
}
.aug-table-hedaing {
    color: #170F49;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}
.aug-table-hedaingWraper {
    display: flex;
    align-items: center;
    gap: 16px 3px;
}
.aug-table-headSm {
    font-size: 18px;
    font-weight: 500;
    padding-left: 4px;
}
.sep-trail-table {
    border: 1px solid #EBECF3;
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    margin-top: 12px;
}
.sep-AffiliateTable-main thead  {
    display: table-header-group;
    background-color: #E0E4E8;
}
.sep-End-Month>div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.sep-data-table-area {
    margin-bottom: 30px;
}
.sep-AffiliateTable-main thead th {
    white-space: nowrap !important;
    padding: 14px 12px 10px;
    color: #170F49 !important;
    font-weight: 600;
    font-family: 'DM Sans';
}
.sep-AffiliateTable-main tbody td {
    border-color: #EBECF3;
    color: #767676;
    font-size: 14px;
    font-family: 'DM Sans';
    white-space: nowrap !important;
    padding: 12px 10px;
    font-weight: 500;
}
.sep-AffiliateTable-main tbody tr:nth-child(odd) td {
    background: #fff;
}
.sep-AffiliateTable-main tbody tr:nth-child(even) td {
    background: #f6f6f6;
}
.sep-AffiliateTable-main tbody tr:hover td {
    background: #f6f6f6;
}
.sep-table-tags {
    min-width: 80px;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    padding: 6px 10px;
    color: #fff;
    font-family: 'DM Sans';
    font-weight: 600;
}
span.sep-avatar-wraper {
    display: flex;
    align-items: center;
    gap: 10px;
}
.sep-table-username {
    font-size: 14px;
}
.sep-table-email {
    font-size: 12px;
    line-height: 1;
}
.sep-avatar-wraper>span {
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.sep-avatar-wraper .MuiAvatar-root {
    border-radius: 8px;
    border: 1px solid rgb(94 59 183 / 20%);
    background: #E2E2E2;
    width: 36px;
    height: 36px;
    color: rgb(23 15 73 / 75%);
}

.sep-title-area {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 12px 0 6px;
}
.sep-title-area-left h2 {
    font-size: 20px;
    font-weight: 600;
    font-family: 'DM Sans';
}
.sep-title-area-right {
    display: flex;
    align-items: center;
    gap: 10px;
}
button.sep-filter-btn {
    background: #2C73FF;
    border-radius: 7px;
    border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 20px;
    text-transform: capitalize;
    font-family: 'DM Sans';
    font-weight: 500;
    letter-spacing: 0;
}
button.sep-filter-btn:hover {
    background: #1d63ed;
    box-shadow: none;
}
a.sep-viewlist-btn {
    background: #22C55E;
    border-radius: 7px;
    border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 20px;
    text-transform: capitalize;
    font-family: 'DM Sans';
    font-weight: 500;
    letter-spacing: 0;
}
a.sep-viewlist-btn:hover {
    background: #16a84c;
    box-shadow: none;
}
button.sep-filter-btn svg {
    width: 20px;
    height: 20px;
}
.sep-viewlist-btn svg {
    width: 20px;
    height: 21px;
}
.sep-title-area-right legend.sep-title-select {
    display: none;
}
.sep-title-area-right .MuiInputBase-root.MuiOutlinedInput-root {
    border: 1px solid #C4CCD2;
    border-radius: 8px;
    background: #f6f6f6;
    height: 36px;
}
.sep-grid-table-cards {
    display: grid;
    grid-template-columns: repeat(3, calc(33.33% - 12px));
    gap: 16px;
    justify-content: space-between;
    margin-bottom: 30px;
}
.sep-grid-table-cards>div {
    box-shadow: none;
    border: 1px solid rgb(44 115 255 / 20%);
}
.sep-grid-table-cards .MuiCardHeader-root {
    padding: 14px 12px 12px;
    border-bottom: 1px solid rgb(44 115 255 / 20%);
}
.sep-grid-table-cards .MuiAvatar-root.MuiAvatar-circular {
    border-radius: 8px;
    border: 1px solid rgb(94 59 183 / 20%);
    background: #E2E2E2;
    width: 45px;
    height: 45px;
    color: rgb(23 15 73 / 75%);
    font-weight: 600;
    font-family: 'DM Sans';
}
.sep-grid-table-cards hr.MuiDivider-root.MuiDivider-middle {
    display: none;
}
.sep-grid-table-cards .MuiCardHeader-root p.MuiTypography-root {
    font-size: 16px;
    font-weight: 700;
    color: #170F49;
    font-family: 'DM Sans';
}
.sep-grid-table-cards .MuiCardHeader-avatar {
    margin-right: 10px;
}
.sep-grid-table-cards .MuiCardHeader-root p.MuiTypography-root {
    font-size: 16px;
    font-weight: 600;
    color: #170F49;
    font-family: 'DM Sans';
}
.sep-grid-table-cards .MuiCardHeader-avatar {
    margin-right: 10px;
}
.sep-grid-table-cards .MuiCardContent-root {
    padding: 8px 14px !important;
}
.sep-grid-table-cards .MuiCardContent-root th, .sep-grid-table-cards .MuiCardContent-root td {
    white-space: nowrap;
    font-family: 'DM Sans';
    font-weight: 600 !important;
}
.sep-grid-table-cards .MuiCardContent-root th {
    padding: 5px 20px 5px 0 !important;
    color: #170F49 !important;
}
.sep-grid-table-cards .MuiCardContent-root td {
    padding: 5px  0 !important;
    color: #2C73FF !important;
}
.sep-gridview-tags {
    background: #2C73FF;
    border-radius: 6px;
    padding: 6px 18px;
    color: #fff;
}
.sep-NVL-table1 table tbody td {
    color: #767676 !important;
    font-family: 'DM Sans';
}
div.sep-AffiliateTable-div {
    margin: 0 0 10px;
    padding-top: 0;
    width: 100%;
    background: white !important;
    box-shadow: none;
    border-radius: 12px;
    overflow-x: auto;
    border: 1px solid #ebecf3;
}

.oct-affiliate-strip {
    position: relative;
    padding-top: 10px !important;
    display: grid;
    grid-template-columns: 4fr 1fr;
    background-color: rgb(255, 255, 255);
    padding: 0px 24px;
    border-radius: 0px 12px 0px 0px;
}
.sep-table-tabsInner {
    display: flex;
    gap: 0px 10px;
    align-items: center;
}

.oct-affiliate-strip::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 12px;
    background: #ffffff;
    bottom: -3px;
}
.sep-AffiliateTable-main * {
    font-family: 'DM Sans' !important;
}

.sep-AffiliateTable-main .MuiAvatar-circular {
    background-color: #b1b4ba;
    border-radius: 8px;
    border: 1px solid #2c73ff26;
}
.sep-AffiliateTable-main .MuiChip-filled {
    border-radius: 6px;
    line-height: 1;
    text-transform: capitalize;
    font-family: 'DM Sans';
}
.sep-AffiliateTable-div {
    overflow: auto;
}
.data-table-area button.tabmenu-btn {
    border-radius: 12px 12px 0 0 !important;
    border: 1px solid rgb(228, 228, 228);
}
.data-table-area button.tabmenu-btn:nth-child(01),
.data-table-area button.tabmenu-btn:nth-child(02) {
    border: 1px solid rgb(228, 228, 228);
}
.data-table-area button.tabmenu-btn[style*="background-color: rgb(44, 115, 255)"]:nth-child(01),
.data-table-area button.tabmenu-btn[style*="background-color: rgb(44, 115, 255)"]:nth-child(02),
.data-table-area button.tabmenu-btn[style*="background-color: rgb(44, 115, 255)"]:nth-child(05)  {
    border: 1px solid rgb(28 192 103 / 100%);
    color: #fff !important;
    background-color: rgb(28 192 103 / 100%) !important;
}
.data-table-area button.tabmenu-btn:nth-child(03),
.data-table-area button.tabmenu-btn:nth-child(04) { 
    border: 1px solid rgb(228, 228, 228);
}
.data-table-area button.tabmenu-btn[style*="background-color: rgb(44, 115, 255)"]:nth-child(03),
.data-table-area button.tabmenu-btn[style*="background-color: rgb(44, 115, 255)"]:nth-child(04) { 
    border: 1px solid rgb(255 2 2 / 100%);
    color: #fff !important;
    background-color: rgb(255 2 2 / 100%) !important;
}


.sep-AffiliateTable-main * .MuiTableCell-head * {
    line-height: 1.1 !important;
}
.sep-AffiliateTable-main .MuiTableCell-head {
    background: #e0e4e8;
}
.oct-payment-table {
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #ebecf3;
    margin-top: 12px;
}
label[for="items-per-page"] {
    font-weight: 500;
    font-size: 15px;
}
.table-outer-box {
    padding: 10px 20px 15px;
    background: #ffffff;
    box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
    border-radius: 0 0 12px 12px;
}
.table-outer-box>div:last-child {
    padding: 10px 20px !important;
}
.table-outer-box>div:last-child button {
    outline: 1px solid #2c73ff42;
    padding: 7px !important;
    min-width: 36px;
}
span.sep-active-sale-line::after {
    content: '';
    width: 1px;
    height: 13px;
    background: #8db4ff;
    position: absolute;
    top: -13px;
    left: 50%;
}
.sep-timeSpan-btm {
    color: #2C73FF;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
}
.sep-earning-bouns-cards {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
    margin-top: 20px;
}
.sep-paymentable-inner {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 12px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 5px 20px #0000001a;
    margin-bottom: 30px;
}
.sep-paymentable-inner .sep-title-18 {
    margin: 0px;
}
.sep-active-sale-line {
    position: absolute;
    right: calc(0% - 16px);
    bottom: -38px;
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 16.45px;
    text-align: center;
    color: rgb(44, 115, 255);
    border: 1px solid rgba(44, 115, 255, 0.5);
    background: rgba(44, 115, 255, 0.1);
    padding: 6px;
    border-radius: 50%;
    height: 32px;
    width: 32px;
}
.sep-as-prog {
    height: 8px;
    margin-bottom: 30px;
    background: rgba(44, 115, 255, 0.2);
    border-radius: 2px;
    position: relative;
}
.sep-as-progInner {
    height: 8px;
    background: rgb(44, 115, 255);
    border-radius: 2px;
    position: relative;
}
.oct-affiliate-stripInner {
    display: flex;
    align-items: center;
}
.oct-affiliate-stripBtn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
}
.sep-blue-Btns {
    background: rgb(44, 115, 255);
    padding: 12px 16px;
    color: rgb(255, 255, 255);
    font-family: "DM Sans";
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    text-align: left;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
}
.nova-addExtension-popup button.install-extension-btn {
    align-items: center;
    display: flex;
    gap: 8px;
    margin-top: 8px;
    background-color: #2c73ff;
}
.novalya-dashboard-right.nova-addExtension-popup {
    border: 1px solid #d4e2ff;
    padding: 25px;
    border-radius: 16px;
    background: white;
    width: 350px;
    margin: 50px auto auto;
    
}
.oct-affiliate-stripInner button {
    box-shadow: none;
    letter-spacing: 0;
    border-radius: 6px;
    font-family: 'DM Sans';
    font-weight: 600;
}

@media (min-width: 1650px) {
    .sep-grid-table-cards {
        display: grid;
        grid-template-columns: repeat(4, calc(25% - 12px));
        gap: 16px;
    }
}

@media (min-width: 1800px) {
    .sep-affiliate-dashboard {
        zoom: 1.3;
    }
}


@media (min-width: 2250px) {
    .sep-affiliate-dashboard, .sep-grid-table-cards {
        zoom: 1.45;
    } 
    .sep-grid-table-cards {
        zoom: 1.25;
    }   
}

@media (min-width: 2650px) {
    .sep-affiliate-dashboard {
        zoom: 1.65;
    }
    .sep-grid-table-cards {
        zoom: 1.65;
    }
}

@media (max-width: 1200px) {
    .sep-affiliateMenu.sep-affiliateMenu-2 + div {
        padding: 0 20px;
    }
    .oct-affiliate-strip {
        padding: 0;
        background: #f6f6f9;
    }
    .table-outer-box {
        padding: 0;
        background: #f6f6f9;
        box-shadow: none;
        border-radius: 0;
    }
    .oct-affiliate-strip::after {
        opacity: 0;
    }
    .sep-title-18 {
        font-size: 16px;
        line-height: 24px;
    }
    .sep-paymentable-inner {
        padding: 0;
        background-color: rgb(246 246 249);
        box-shadow: none;
    }
}

@media (max-width: 1023px) {
    .sep-affiliate-dashboard {
        padding: 0 20px;
    }
    .sep-grid-table-cards {
        grid-template-columns: repeat(2, calc(50% - 12px));
    }
    .sep-affiliate-dashboard-cards {
        grid-template-columns: 1fr 1fr;
    }
    .sep-earning-outer {
        grid-template-columns: 100%;
    }
    .sep-earning-wrap>div {
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        gap: 20px;
    }
    .sep-left-earning-level {
        margin: 0;
    }
    .sep-earning-bouns-cards {
        grid-template-columns: 1fr;
    }
    .sep-actual-payout {
        grid-template-columns: 1fr;
        gap: 0;
    }

}

@media (max-width:767px) {
    .sep-affiliate-time {
        margin-bottom: 10px;
    }
    .sep-grid-table-cards {
        grid-template-columns: 100%;
    }
    .sep-affiliate-dashboard-cards {
        grid-template-columns: 1fr;
    }
    .sep-dashpayout-wrap {
        grid-template-columns: 100%;
    }
    .sep-dashpayout-inn {
        padding: 12px;
        margin: 20px 0 0;
    }
    .sep-dashpayout-wrap {
        margin-bottom: 36px;
    }
    .oct-affiliate-strip {
        grid-template-columns: 1fr;
    }
    
}

/* _________ CSS For Affiliate link _________ */
.sep-affiliate-shadowMain {
    box-shadow: 0 5px 20px rgb(0 0 0 / 7%) !important;
    padding: 30px 50px;
    border-radius: 16px !important;
    margin-bottom: 30px;
}
.sep-affiliate-link-header {
    background: rgb(44 115 255 / 10%);
    border: 1px dashed rgb(44 115 255 / 50%);
    padding: 7px 7px 7px 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
}
.sep-affiliate-link-title {
    font-size: 18px;
    color: #2C73FF;
    line-height: 1.35;
}
.sep-affiliate-link-title strong {
    text-transform: uppercase;
    font-weight: 600;
}
.sep-affiliate-link-header button {
    box-shadow: none;
    font-size: 16px;
    padding: 10px 20px;
    letter-spacing: 0;
    font-family: 'DM Sans';
}
.sep-affiliate-link-header button:hover {
    background: #2c73ff;
    box-shadow: none;
}

.sep-affiliate-link-title {
    font-size: 20px;
    color: #2C73FF;
    line-height: 1.2;
}
.sep-affiliate-link-title strong {
    text-transform: uppercase;
    font-weight: 600;
}
.sep-affilaite-landInner {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 24px 0;
    flex-wrap: wrap;
}
.sep-affilaite-landInner select {
    width: 100%;
    max-width: 225px;
    border: 1px solid #E7EAEE;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    padding: 7px 16px;
}   
.sep-affilaite-landInner>p {
    font-size: 18px;
    font-family: 'DM Sans';
    padding-bottom: 3px;
    font-weight: 600;
}
.sep-affiliateGrid-items {
    display: grid;
    grid-template-columns: 2fr 10fr;
    gap: 25px;
    margin: 15px 0px;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 10px;
}
.sep-alliliate-gridcol>div {
    display: grid;
    grid-template-columns: 225px 1fr;
    gap: 30px;
    border: 1px solid #DBDDE2;
    border-radius: 12px;
}
.sep-alliliate-gridcol {
    display: flex;
    flex-direction: column;
}
.sep-affiliatelink-img {
    background-size: cover;
    background-position: center center;
    border-radius: 12px 0 0 12px;
    background-repeat: no-repeat;
}
.sep-affiliatelink-content {
    padding: 16px 24px 16px 0;
}
.sep-affiliatelink-hdg {
    font-size: 16px;
    font-weight: 600;
    color: #170F49;
}
.sep-affiliatelink-input {
    display: flex;
    gap: 16px;
    align-items: center;
    margin: 6px 0 12px;
}
.sep-affiliatelink-input>div {
    flex-grow: 1;
}
.sep-affiliatelink-input>div {
    letter-spacing: 0;
    margin: 0;
    border-bottom: 2px solid #DADADA;
    border-radius: 8px;
}
.sep-affiliatelink-input>button {
    letter-spacing: 0;
    text-transform: capitalize;
    padding: 8px 24px;
    white-space: nowrap;
    border-radius: 8px;
    box-shadow: none;
    background: #41CE81;
}
.sep-affiliatelink-input>button:hover {
    background: #3abf76;
    box-shadow: none;
}
.sep-affiliatelink-input input {
    color: #858585;
    font-weight: 400;
    font-family: 'DM Sans';
    font-size: 14px;
    background: #F6F6F6;
    border-radius: 7px;
}
.sep-affiliatelink-input .MuiInputBase-root.MuiOutlinedInput-root {
    background: #f6f6f6;
    border-radius: 8px;
}
.sep-affiliatelink-input .sep-affiliate-globe {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #dbdbdb !important;
    padding: 6px;
}
.sep-affiliatelink-input fieldset.MuiOutlinedInput-notchedOutline {
    border: none;
}
.sep-affiliatelink-input .MuiInputAdornment-root.MuiInputAdornment-positionCenter.MuiInputAdornment-sizeMedium {
    max-height: 40px;
    border-radius: 7px;
}
.sep-affiliate-landInner {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 16px 0 0;
}
.sep-affiliate-landInner select {
    max-width: 225px;
}
.sep-affiliateLink-foot {
    font-size: 14px;
    color: #585858 !important;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
}
.sep-affiliateLink-foot a {
    color: #2C73FF !important;
}
.sep-affiliateLink-foot span {
    color: #585858;
}

/* _________ Pool & Level commission _________ */
.sep-title-24 {
    font-size: 24px;
    font-family: 'DM Sans';
    font-weight: 600;
    margin-bottom: 16px;
}
.sep-commisionBox {
    box-shadow: 0 5px 20px rgb(0 0 0 / 7%);
    padding: 24px 36px;
    min-height: calc(100vh - 375px);
}
.sep-commisionTopbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 20px;
}

.sep-commisionSearch {
    border: 1px solid #E0E1E3 !important;
    border-radius: 6px;
    width: 250px;
}
.sep-commisionSearch button {
    padding: 0 10px;
    border-right: 1px solid #e0e1e3;
    border-radius: 0;
}
.sep-commisionSearch input {
    padding: 6px 6px 6px 20px !important;
    color: #8A8C8D;
    font-family: 'DM Sans';
}
.sep-commisionSearch .MuiInputBase-formControl {
    padding-left: 0;
}
.sep-commisionSearch label.MuiInputLabel-formControl {
    display: none;
}
.sep-commisionSearch fieldset.MuiOutlinedInput-notchedOutline {
    border: none;
}
.sep-affSettingMain {
    display: grid;
    grid-template-columns: calc(100% - 350px) 325px;
    gap: 36px 24px;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 30px;
}
.sep-affSettingMain-rightInner {
    border-radius: 12px;
    background-size: cover;
    background-position: right bottom;
    padding: 20px;
}
.sep-affSettingMain-right {
    background: rgb(44 115 255 / 10%);
    border-radius: 12px;
}
.sep-affSettingMain-rightInner>div {
    display: flex;
    flex-direction: column;
    min-height: 290px;
    align-items: center;
    justify-content: center;
    gap: 24px;
}
.sep-affSettingMain-rightTitle {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -.35px !important;
    font-family: 'DM Sans';
    text-align: center;
}
button.sep-agreementBtn {
    background: #2C73FF;
    border-radius: 12px;
    border: none;
    outline: 3px solid rgb(44 115 255 / 20%);
    padding: 15px 30px;
    width: 100%;
    max-width: 225px;
    display: block;
    margin: 0 auto;
    font-size: 16px;
}
.sep-affSettingMain-rightInner div {
    width: 100%;
}
.sep-compensation-btn {
    background: #fff;
    border-radius: 12px;
    border: none;
    border: 1px solid rgb(44 115 255 / 50%);
    padding: 15px 30px;
    width: 100%;
    max-width: 225px;
    color: #2C73FF;
    display: block;
    margin: 0 auto;
    font-size: 16px;
}
.sep-agreementBtn:hover {
    background: #2668eb;
}
.sep-compensation-btn:hover {
    border: 1px solid rgb(44 115 255 / 75%);
    background: #2C73FF;
    color: #fff;
}
.sep-affSetting-left {
    box-shadow: 0 5px 20px rgb(0 0 0 / 7%) !important;
    border-radius: 12px !important;
    padding: 30px  24px;
}
.sep-affSetting-leftTop {
    display: flex;
    align-items: start;
    gap: 30px;
}
.sep-affSetting-TopInner>label {
    font-size: 16px;
    font-weight: 500;
    color: #170F49;
}
.sep-affSetting-TopInner {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 10px;
}
.sep-affSetting-TopInner button {
    box-shadow: none;
    border-radius: 10px;
    font-size: 15px;
    letter-spacing: 0;
    text-transform: capitalize;
    outline: 3px solid rgb(255 200 4 / 20%);
    padding: 8px 24px;
}
.sep-affSetting-TopInner button:hover {
    box-shadow: 0 4px 4px rgb(0 0 0 / 10%) !important;
}
.sep-affSettingMain .MuiAlert-root.MuiAlert-colorInfo {
    border-radius: 10px;
    border: 1px dashed #6ab2d9;
    background: #F1FAFF !important;
    padding: 12px 24px;
}
.sep-affSettingMain .MuiAlert-root.MuiAlert-colorInfo svg {
    fill: #0075B7;
}
.sep-affSettingMain .MuiAlert-message {
    font-size: 14px;
    color: #0075B7;
}


/* ________ CSS for model __________ */
.sep-affiliateLinkUpdate .MuiPaper-elevation.MuiPaper-rounded {
    border-radius: 16px ;
}
.sep-affiliateLinkUpdate .MuiPaper-elevation.MuiPaper-rounded h2 {
    background: #f6f6f6;
    font-size: 20px;
    font-weight: 500;
    font-family: 'DM Sans';
}
.sep-affiliateLinkUpdate .MuiTypography-root label {
    font-size: 14px;
    color: #170F49 !important;
    margin-bottom: 4px;
    display: block;
}
.sep-affiliateLinkUpdate input {
    background: #f6f6f6!important;
    border-radius: 8px;
    font-size: 14px;
}
.sep-affiliateLinkUpdate .MuiDialogContent-root>.MuiTypography-root {
    margin-top: 10px;
    margin-bottom: 20px;
}
.sep-affiliateLinkUpdate .MuiTypography-root.MuiDialogContentText-root>div {
    margin-bottom: 20px;
}
.sep-affiliateLinkUpdate .MuiDialogContent-root {
    padding: 0 35px;
}
.sep-affiliateLinkUpdate .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    width: 100%;
    max-width: 700px;
}
.sep-affiliateLinkUpdate .MuiDialogActions-root.MuiDialogActions-spacing {
    display: flex;
    align-items: center;
    gap: 10px;
}
.sep-affiliateLinkUpdate button.MuiButton-colorSuccess {
    cursor: pointer;
    background: #41CE81 !important;
    color: #fff !important;
    box-shadow: none !important;
    border-radius: 8px;
    text-transform: capitalize;
    padding: 7px 20px;
    letter-spacing: 0;
}
.sep-affiliateLinkUpdate .sep-affiliateLinkUpdate button.MuiButton-colorSuccess:hover {
    background: #30af69 !important;
}
.sep-affiliateLinkUpdate button.MuiButton-colorPrimary {
    background: #D3D3D3;
    text-transform: capitalize;
    color: #170f49;
    letter-spacing: 0;
    border-radius: 8px;
    border: none;
    padding: 6px 20px;
    margin: 0;
}
.sep-affiliateLinkUpdate button.MuiButton-colorPrimary:hover {
    border: none;
    background: #c5c4c4;
}
.sep-affiliateLinkUpdate label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
    display: flex;
    align-items: start;
}
.sep-affiliateLinkUpdate .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0 8px 0 8px;
    opacity: .75;
    transform: scale(.9);
}
.sep-affiliateLinkUpdate .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
    font-size: 14px;
    font-family: 'DM Sans';
    letter-spacing: 0;
    line-height: 1.35;
    padding-top: 3px;
}
.sep-affSetting-left .MuiButton-colorError {
    background: #FF0202;
}
.sep-affSetting-left .MuiButton-colorError:hover {
    background: #f50101 !important;
}
.sep-affSetting-left .MuiAlert-colorError {
    background: rgb(255 2 2 / 5%);
    box-shadow: none !important;
    border: 1px dashed #FF0202;
    border-radius: 10px;
    color: #FF0202;
    margin-top: 24px;
}
.sep-affSetting-left .MuiAlert-filledError .MuiAlert-message {
    color: #170F49;
}
div.sep-radio-group {
    display: flex;
    gap: 20px;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
}
.sep-title18 {
    font-size: 18px;
    font-weight: 500;
    color: #170F49;
}
.sep-verification-radio {
    margin-top: 36px;
}
.sep-affSettingMain .sep-radio-btn {
    border: 1px solid rgb(44 115 255 / 35%);
    margin: 0;
    padding: 10px;
    border-radius: 10px;
    background: rgb(44 115 255 / 7%);
    color: #637381;
    font-family: 'DM Sans' !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    min-width: 152px;
    height: 40px;
}
.sep-verification-radio .sep-radio-btn span {
    font-family: 'DM Sans';
    font-size: 16px;
    font-weight: 500;
}
.sep-verification-radio .sep-radio-btn span.MuiTypography-body1 {
    color: #637381;
}
.sep-AffiliateUpload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: linear-gradient(45deg, #1E5DFF, #58BEFF);
    border-radius: 50%;
}
.sep-or-span {
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 500;
    line-height: 18.23px;
    text-align: left;
}
.sep-idCols {
    display: grid;
    grid-template-columns: calc(50% - 12px) calc(50% - 12px);
    gap: 12px 24px;
    margin-top: 24px;
    margin-bottom: 36px;
}
.sep-fs-14 {
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 500;
    color: #170F49;
    line-height: 1.35;
    margin-bottom: 10px;
}
.sep-upload-btn {
    border: 1px dashed rgb(44 115 255 / 65%);
    min-height: 95px;
    display: flex;
    justify-content: center;
    background: #F1FAFF;
    align-items: center;
    border-radius: 12px;
    font-family: "DM Sans";
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
    gap: 8px 15px;
    color: #170F49;
    cursor: pointer;
    flex-wrap: wrap;
    padding: 15px;
}
.sep-upload-btn svg {
    fill: #fff;
    width: 18px;
    height: 18px;
}
.sep-upload-icon {
    width: 40px;
    height: 40px;
    background: linear-gradient(45deg, #2c73ff, #5BC3FF);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.sep-upload-btn span b {
    text-decoration: underline;
    font-weight: 600;
    white-space: nowrap;
}
.sep-upload-foot {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-family: "DM Sans";
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3;
    text-align: left;
    color: #5E5E5E;
    gap: 5px;
    flex-wrap: wrap;
}
.sep-upload-imgWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;
    border: 1px solid rgba(94, 59, 183, 0.15);
    border-radius: 10px;
    max-width: 225px;
    margin: 15px 0px;
}
.sep-upload-imgSize {
    width: 100%;
    max-width: 210px;
    padding: 5px;
}
.sep-upload-imgWrap span {
    display: block;
    border-bottom: 1px solid rgba(94, 59, 183, 0.15);
    width: 100%;
    padding: 6px 10px;
}
button.sep-greenBtn {
    background: #41CE81;
    border-radius: 10px;
    box-shadow: none;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 0;
    font-family: 'DM Sans' !important;
    gap: 8px;
    padding: 8px 24px;
}
button.sep-greenBtn:hover {
    background: #3bc178;
    box-shadow: none;
}

button.sep-greenBtn span {
    font-family: 'DM Sans' !important;
}
.sep-affiliate-formTitle {
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 1.3;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 30px;
}
.sep-affiliate-formGroup {
    display: grid;
    grid-template-columns: calc(50% - 12px) calc(50% - 12px);
    gap: 16px 24px;
    margin-bottom: 24px;
}
.sep-affiliate-formGroup label.MuiInputLabel-formControl {
    position: unset !important;
    transform: unset;
    margin-bottom: 8px;
    color: #475259;
    font-family: 'DM Sans';
    font-size: 16px;
    overflow: visible;
}
.sep-affiliate-formGroup .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    padding: 0;
}
.sep-affiliate-formGroup .MuiOutlinedInput-root.MuiInputBase-formControl {
    outline: 1px solid rgb(94 59 183 / 12%) !important;
    border-radius: 7px;
    background: rgb(94 59 183 / 5%);
}
.sep-affiliate-formGroup  legend {
    display: none;
}
.sep-affiliate-formGroup .MuiOutlinedInput-input {
    color: #170F49;
    font-family: 'DM Sans' !important;
    padding: 9px 16px 11px  !important;
    font-size: 15px;
}
.sep-affiliate-formGroup fieldset.MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
    position: unset !important;
}
.sep-affiliate-formGroup .MuiInputBase-adornedStart.MuiInputBase-adornedEnd {
    padding-left: 16px !important;
}
.sep-affiliate-formGroup .MuiInputBase-adornedStart.MuiInputBase-adornedEnd input {
    padding-left: 2px !important;
}
.sep-affiliate-formGroup .MuiInputBase-adornedStart.MuiInputBase-adornedEnd img {
    border-radius: 3px;
    width: 32px !important;
    height: 22px !important;
    object-fit: cover;
}
.sep-setting-infoWraper {
    margin-top: 24px;
}
.sep-setting-infoTitle {
    font-family: "DM Sans";
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
    text-align: left;
    margin-bottom: 12px;
    color: #170F49;
}
button.sep-greenBtn.sep-bgBlue {
    background: #2C73FF;
}

button.sep-greenBtn.sep-bgBlue:hover {
    background: #276cf3;
}
.sep-affiliate-formGroup .MuiFormControl-root p.Mui-error {
    margin-left: 0;
    letter-spacing: 0;
    font-family: 'DM Sans';
    font-weight: 500;
    color: #FF0000;
}
.sep-commisionBox {
    margin-bottom: 30px;
}

.sep-affiliate-time {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-bottom: 6px;
}
.sep-timeSpan {
    font-family: Digital-7;
    line-height: 33.57px;
    font-size: 32px;
    font-weight: 400;
    background: linear-gradient(0deg, rgb(255 255 255 / 85%), rgb(255 255 255 / 25%));
    padding: 0px 2px;
    color: #2C73FF;
    display: inline-flex;
    border-radius: 3px;
    border: 1px solid rgb(255 255 255 / 65%);
    width: 24px;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
}
.sep-title-18 {
    font-size: 18px;
    line-height: 32px;
    font-weight: 600;
    color: #170f49;
}
.sep-shadowCards {
    padding: 20px;
    background: rgb(255, 255, 255);
    border-radius: 12px;
    box-shadow: rgba(145, 158, 171, 0.15) 0px 7px 20px;
}
.sep-earningItems {
    border: 1px solid rgba(145, 158, 171, 0.24);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 13px 15px;
    color: #4C545D;
    font-size: 14px;
    font-family: 'DM Sans';
    line-height: 1.35;
    font-weight: 500;
    margin: 16px 0px;
    background: rgb(249, 250, 251);
}
.sep-earningItemsGreen {
    border: 1px solid #E9FCD4;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 13px 15px;
    color: rgba(33, 43, 54, 0.8);
    font-size: 14px;    
    line-height: 24px;
    font-weight: 500;
    margin: 10px 0px;
    background: #E9FCD4;
}
.sep-p {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(33, 43, 54, 0.8);
    margin-top: 5px;
}
.sep-bonusRows {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1.35fr 1fr;
    gap: 20px;
}
.sep-bonusBorderCards {
    background: rgba(44, 115, 255, 0.05);
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    margin-bottom: 15px;
    border-radius: 12px;
    border: 1px solid rgba(44, 115, 255, 0.15);
    padding: 8px;
    text-transform: uppercase;
}
.sep-bonusBlueText {
    font-family: "DM Sans";
    font-size: 21px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    color: rgb(44, 115, 255);
}
.sep-bonusRows .sep-title-18 {
    text-align: center;
    margin-bottom: 5px;
}
.sep-grid-box {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px 5px;
    border-radius: 12px;
    margin: 20px 0px;
}
.sep-actual-payout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 12px;
    background: rgba(44, 115, 255, 0.1);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px 5px;
    padding: 20px;
    align-items: center;
    gap: 16px;
}
.sep-title-24 {
    color: rgb(27, 100, 243);
    font-size: 24px;
    line-height: 1.25;
}
.sep-NVL-table1  {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px 5px;
    border-radius: 8px;
    margin: 20px 0px;
    padding: 20px;
}
.sep-NVL-table1 th.MuiTableCell-root {
    line-height: 1.25;
}
.sep-data-table-area {
    background-color: transparent;
    box-shadow: none;
    border-radius: 12px;
    margin: 30px 0px 15px;
    padding: 0px;
}
.sep-table-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sep-levelCommision.sep-AffiliateTable-main thead th {
    padding: 14px 10px 10px;
}

.levelcommision-head {
    display: flex;
    align-items: center;
    gap: 12px;
}
.levelcommision-head>div {
    display: flex;
    gap: 12px;
}
.levelcommision-head>div button {
    letter-spacing: 0;
    border-radius: 6px;
    box-shadow: none;
    text-transform: capitalize;
    font-family: 'DM Sans';
    font-weight: 600;
}
.levelcommision-head>div button:hover {
    background: #2c73ff;
    color: #fff;
    box-shadow: none;
}
.levelcommision-main {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px 5px;
    border-radius: 12px;
    margin: 0px 0px 20px;
    padding: 20px;
}

div.levelcommision-tableArea  .MuiInputBase-formControl svg {
    fill: #2c73ff;
}
.MuiSelect-select {
    font-family: 'DM Sans' !important;
}
.levelcommision-tableArea .MuiFormControl-root legend>span {
    display: none;
}


@media (min-width: 1650px) {
    .sep-grid-table-cards {
        display: grid;
        grid-template-columns: repeat(4, calc(25% - 12px));
        gap: 16px;
    }
}

@media (min-width: 1800px) {
    /* .sep-affiliate-dashboard, 
    .sep-affiliateMenu, 
    .sep-affiliate-shadowMain, 
    .sep-affSettingMain,
    .sep-titleContainer,
    .sep-commisionBox {
        zoom: 1.3;
    } */
    .sep-affiliateLinkUpdate {
        zoom: 1.35;
    }
}


@media (min-width: 2250px) {
    /* .sep-affiliate-dashboard, 
    .sep-grid-table-cards, 
    .sep-affiliateMenu, 
    .sep-affiliate-shadowMain, 
    .sep-affSettingMain,
    .sep-titleContainer,
    .sep-commisionBox {
        zoom: 1.45;
    }  */
    .sep-grid-table-cards {
        zoom: 1.25;
    }
    .sep-affiliateLinkUpdate {
        zoom: 1.45;
    } 
}

@media (min-width: 2650px) {
    /* .sep-affiliate-dashboard, 
    .sep-grid-table-cards, 
    .sep-affiliateMenu, 
    .sep-affiliate-shadowMain, 
    .sep-affSettingMain,
    .sep-titleContainer,
    .sep-commisionBox {
        zoom: 1.65;
    } */
    .sep-affiliateLinkUpdate {
        zoom: 1.65;
    } 
}


@media (max-width: 1250px) {
    .sep-affiliate-shadowMain {
        padding: 16px !important;
        margin-bottom: 24px;
    }
    .sep-affiliate-dashboard {
        padding: 0 20px;
    }
}

@media (max-width: 1200px) {
    .sep-affSettingMain, 
    .sep-affiliate-dashboard, 
    .sep-titleContainer,
    .sep-commisionBox,
    .sep-affiliateMenu  {
        padding: 0 20px;
    }
    .sep-affiliate-shadowMain {
        padding: 20px;
        margin: 0px 15px;
    }
    .sep-affiliateMenu {
        margin-top: 24px;
    }
    .grid-box.sep-grid-box {
        padding: 0;
        box-shadow: none;
        background: #f6f6f9;
        border: none;
    }
    .actual-payout.sep-actual-payout {
        padding: 0;
        background: #f6f6f9;
        box-shadow: none;
    }
    .nova-container {
        background: #f6f6f9;
    }
    .title-area.levelcommision-main {
        padding: 20px !important;
        margin: 0 20px 20px;
    }
    div.levelcommision-tableArea {
        margin: 0 20px !important;
        padding: 0;
        background-color: #f6f6f9;
        box-shadow: none;
    }
    div.levelcommision-tableArea fieldset.MuiOutlinedInput-notchedOutline {
        border-color: rgb(44 115 255 / 20%) !important;
        border-radius: 7px;
    }
    div.levelcommision-tableArea  .MuiInputBase-formControl {
        background: #fff;
    }
    
    
}

@media (max-width: 1023px) {
    
    .sep-grid-table-cards {
        grid-template-columns: repeat(2, calc(50% - 12px));
    }
    .sep-affiliate-dashboard-cards {
        grid-template-columns: 1fr 1fr;
    }
    .sep-earning-outer {
        grid-template-columns: 100%;
    }
    .sep-earning-wrap>div {
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        gap: 20px;
    }
    .sep-left-earning-level {
        margin: 0;
    }
    .sep-affiliate-link-header {
        padding: 16px;
        flex-wrap: wrap;
    }
    .sep-affiliate-link-title {
        font-size: 18px;
    }
    .sep-affiliateMenu>div {
        width: auto;
    }
    .tabmenu-btn {
        padding: 8px 20px;
        white-space: nowrap;
        min-height: unset;
    }
    .sep-affiliateMenu {
        overflow: auto;
    }
}

@media (max-width:991px) {
    .sep-alliliate-gridcol>div {
        gap: 10px;
        padding: 24px 24px 10px;
        grid-template-columns: 100%;
        background: #fbfbfb;
    }
    .sep-affiliatelink-img {
        padding-top: 45%;
        border-radius: 12px;
    }
    .sep-affiliatelink-content {
        padding: 16px 0 16px 0;
    }
    .sep-affiliateLink-foot {
        flex-wrap: wrap;
    }
    .sep-affiliateLink-foot span {
        width: 100%;
    }
    .sep-affiliatelink-input {
        flex-wrap: wrap;
    }
    .sep-affSettingMain {
        grid-template-columns: 100%;
    }
    .sep-affSettingMain .MuiFormControl-root.MuiTextField-root {
        height: unset;
    }
}

@media (max-width:767px) {
    .sep-grid-table-cards {
        grid-template-columns: 100%;
    }
    .sep-affiliate-dashboard-cards {
        grid-template-columns: 1fr;
    }
    .sep-dashpayout-wrap {
        grid-template-columns: 100%;
    }
    .sep-dashpayout-inn {
        padding: 12px;
        margin: 20px 0 0;
    }
    .sep-dashpayout-wrap {
        margin-bottom: 36px;
    }
    .sep-idCols {
        grid-template-columns: 100%;
    }
    .sep-setting-infoWraper .MuiFormGroup-root {
        gap: 12px;
        margin-bottom: 10px;
    }
    .sep-affiliate-formGroup {
        grid-template-columns: 100%;
    }
    .sep-verification-radio .sep-radio-btn {
        padding: 5px;
        width: 135px;
    }
    .sep-affSettingMain .sep-radio-btn {
        width: 135px;
    }
    div.sep-radio-group {
        gap: 10px;
    }
    .sep-bonusRows {
        grid-template-columns: 100%;
    }
    .oct-affiliate-stripInner {
        align-items: start;
        flex-direction: column;
    }
    .oct-affiliate-stripInner h3 {
        margin: 5px 0 0;
        line-height: 1;
    }
    .oct-affiliate-stripInner>span {
        padding-left: 0 !important;
    }
    .sep-grid-box {
        padding: 16px;
    }
    .actual-payout.sep-actual-payout {
        padding: 0;
        background: unset;
        box-shadow: none;
        grid-template-columns: 100%;
    }
    .sep-actual-payout .NVL-table1.sep-NVL-table1 {
        margin: 0;
        padding: 0;
        overflow-y: auto;
    }
    .sep-data-table-area .sep-AffiliateTable-div + div {
        flex-direction: column;
        gap: 14px;
        justify-content: center;
        align-items: center;
    }
    
    .sep-data-table-area .sep-AffiliateTable-div + div>div {
        justify-content: center;
    }
    .sep-bonusRows {
        grid-template-columns: 100%;
    }
    .oct-affiliate-strip {
        grid-template-columns: 1fr;
    }
    .sep-table-tabsInner {
        max-width: 100%;
        overflow-x: auto;
    }
    .oct-affiliate-stripBtn {
        justify-content: start;
        margin: 6px 0 16px;
    }
    .sep-data-table-area {
        margin: 20px 0px 16px;
    }
    .levelcommision-head {
        flex-direction: column;
    }
    .levelcommision-tableArea .title-area {
        flex-direction: column;
        gap: 12px !important;
    }
    .levelcommision-tableArea .MuiFormControl-root {
        width: 100%;
    }
    .levelcommision-tableArea .title-area-left .MuiFormControl-root {
        width: 100%;
    }

}

