.crm-birthdayOuter ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 10px;
}

.crm-birthdayOuter ::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.crm-birthdayOuter ::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border: 2px solid #bebebe;
  border-radius: 10px;
}

ul {
  padding: 0;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.crm-birthday .message-right {
  padding: 0;
  z-index: 9;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.crm-birthday .group-items input[type="radio"] {
  display: none;
}

.crm-birthday .message-right input[type="radio"]:checked + .group-wraper-right {
  background: #2C73FF;
  color: #fff;
}

.crm-birthday .group-wraper-right {
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  margin-bottom: 0;
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  min-height: 175px;
  background: rgb(224 234 251);
  border-radius: 3px;
}

.crm-birthday {
  display: grid;
  align-items: center;
  gap: 16px;
  grid-template-columns: 0% calc(100% - 16px);
}

.crm-birthday .group-wraper-right.group-when {
  min-height: 44px;
  max-width: 100% !important;
  width: 100%;
  padding: 5px 12px;
  text-align: center;
}
.crm-birthday-wrap {
  min-height: calc(100vh - 160px);
  box-shadow: 0 6px 16px rgb(8 15 52 / 6%);
  background: #fff;
  border-radius: 16px;
  margin-bottom: 24px;
}
.crm-birthdayOuter {
  display: flex;
  flex-wrap: nowrap;
  min-height: 100px;
  gap: 30px;
  border-radius: 12px;
  padding: 24px 30px;
  justify-content: space-between;
}
.crm-birthday-main {
  width: 67%;
  margin-top: 24px;
}

.crm-birthday.message-right-wrap {
  width: 30%;
}

.crm-birthday.crm-birthday-ryt {
  margin-top: 16px;
}

.message-right.message-right-01 {
  grid-template-columns: 1fr;
}

.crm-birthday
  .message-right
  input[type="radio"]:checked
  + .group-wraper-right
  svg
  path {
  fill: #0000;
}
.mesg-hd {
  font-size: 18px;
  font-weight: 600;
  color: #170f49;
  margin: 0;
  text-align: center;
  line-height: 1.25;
}
.crm-birthday-yt.manage-request-right .mesg-hd {
  max-width: 215px;
  margin: 0 auto 6px;
}
.crm-birthday
  .message-right
  input[type="radio"]:checked
  + .group-wraper-right
  svg
  path {
  stroke: #fff;
}

.mesg-hd.mesg-hd-left {
  text-align: left;
}

.crm-birthday-slect select.actiongroup {
  width: calc(100% - 146px);
  background: #fff;
  border: 1px solid #f4eeeb;
  min-height: 50px;
  border-radius: 5px;
  padding: 10px;
}

.crm-birthday-slect {
  margin-top: 24px;
}

.crm-birthday-slect {
  font-size: 14px;
  color: red;
}
.crm-birthday.message-right-wrap {
  display: block;
}
.crm-birthday.message-right-wrap .mesg-hd {
  margin-bottom: 24px;
}
.send_birthday_message {
  background: #2C73FF;
  margin: 0px  auto 0 !important;
  outline: 2px solid rgb(44 115 255 / 25%);
  border: none !important;
  text-transform: uppercase;
  font-size: 14px;
  padding: 6px 24px;
  border-radius: 8px !important;
  height: 42px !important;
}
.crm-birthdayOuter-btns {
  width: 100%;
  margin-bottom: 16px;
}
.message-right.message-right-01 {
  grid-template-columns: 1fr;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  gap: 0px;
  display: flex;
  flex-direction: column;
}

/* .crm-birthday-wrap .message-right.message-right-01 {
  height: 100%;
} */

.message-right.message-right-01 .group-items {
  min-height: 44px;
}



.nw-bitrhday-bgWrap {
  background: #FCFBFB;
  border: 1px solid #F1EFEF;
  border-radius: 16px;
  padding: 20px 30px;
  margin-bottom: 20px;
}
.nw-BirthTitle {
  font-size: 21px;
  line-height: 1.1;
  color: #170F49;
  font-weight: 600;
  margin-bottom: 15px;
  display: block;
}
.nw-BirthTitle>span {
  font-weight: 400;
}
.nw-birth-bgWrap {
  background: #E2EAF9;
  border-radius: 8px;
  border: 1px solid #D6E3FC;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  gap: 14px;
  font-weight: 600;
  color: #170F49;
  font-size: 14px;
  cursor: pointer;
}
.birth-fs-16 {
  font-size: 16px;
  font-weight: 500;
}
.nw-birth-label {
  display: block;
}
.nw-birth-label input[type="radio"]:checked + .nw-birth-bgWrap {
  background: #4071F6;
  color: #fff;
}
.nw-birth-label input[type="radio"]:checked + .nw-birth-bgWrap .birth-fillStroke path {
  stroke: #fff;
  fill: #fff;
}
.nw-birth-label input[type="radio"]:checked + .nw-birth-bgWrap .birth-fillStrokeOp path {
  fill: #fff;
  stroke: #4071f6;
  fill-opacity: 1;
}
.nw-birth-label input[type="radio"] {
  display: none;
}
.nw-birth-col2 {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.nw-birth-col2>.nw-birth-label {
  width: calc(50% - 12px);
  max-width: 350px;
}
.nw-bitrhday-subtitleWrap {
  display: grid;
  grid-template-columns: 125px 1fr;
  align-items: start;
  margin-bottom: 24px;
}
.nw-BirthSubtitle {
  width: 115px;
  font-size: 18px;
  font-weight: 600;
  padding-top: 11px;
}
.nw-birth-col3 {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 16px;
}
.nw-birth-col3>label {
  width: calc(33.33% - 12px);
  max-width: 215px;
}
.nw-tagSelect {
  width: 50%;
  max-width: 250px;
}
.nw-tagSelect select {
  width: 100%;
  background: #fff;
  border: 1px solid #D0E1FF;
  font-size: 14px;
  color: #170F49;
  font-family: 'DM Sans';
  font-weight: 500;
  padding: 10px 20px 10px 10px;
  border-radius: 8px;
  background-image: url(../../../assets/img/select-dropArrow.svg);
  appearance: none;
  background-repeat: no-repeat;
  background-position: 97% center;
}
.nw-tagSelect select:focus {
  outline: none;
}
.nw-birth-bgWrap span {
  display: flex;
  align-items: center;
}
.crm-birthdayOuter-new .group-name {
  font-size: 14px;
  line-height: 1;
}
.nw-birth-bgWrap .group-name {
  padding: 0;
}
.nw-bitrhday-bgWrap .nw-bitrhday-subtitleWrap:last-child {
  margin-bottom: 10px;
}
.crm-birthdayOuter-new .nw-bitrhday-container {
  width: calc(70% - 30px);
  max-height: calc(100vh - 270px);
  overflow-y: scroll;
  padding-right: 5px;
}
.crm-birthdayOuter-new .message-right.message-right-01 {
  max-height: calc(100vh - 305px);
}
.nw-birth-col2 .nw-birth-bgWrap {
  border-radius: 12px;
}
.crm-birthdayOuter-new .crm-birthday.message-right-wrap .mesg-hd {
  margin-bottom: 10px;
}
.custom-search-class-nova+.MuiBox-root button {
  white-space: nowrap;
}


@media (min-width: 1600px) {
  .crm-birthdayOuter-new .group-name {
      font-size: 16px;
  }
  .nw-BirthSubtitle {
    width: 135px;
    font-size: 20px;
    padding-top: 8px;
  }
  .nw-BirthTitle {
    font-size: 24px;
    line-height: 1.1;
    margin-bottom: 18px;
  }
}

@media (max-width: 1200px) {
  .crm-birthday-wrap {
    margin: 0 20px;
  }
}


@media (max-width: 991px) {
  .crm-birthdayOuter {
    grid-template-columns: 1fr;
    padding: 20px 0;
  }
  .crm-birthday-main, .crm-birthday.message-right-wrap {
    width: 100%;
  }
  .crm-birthdayOuter-new .crm-birthday.message-right-wrap {
    margin-right: 0;
  }
  .crm-birthdayOuter-btns {
      margin-top: 0px;
      margin-bottom: 24px;
  }
  .crm-birthday .message-right.message-right-01 {
    max-height: unset;
  }
  .crm-birthday-main {
    margin-top: 0;
  }
}

@media screen and (min-device-width: 100px) and (max-device-width: 768px) {
  .crm-birthday .message-right {
    grid-template-columns: 1fr;
  }

  .crm-birthday {
    align-items: flex-start;
    grid-template-columns: 100%;
  }

  .crm-birthday .group-wraper-right {
    max-width: 100%;
  }
}