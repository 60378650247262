@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --black: #000;
  --white: #fff;
  --white1: rgba(255, 255, 255, 0.1);
  --white2: rgba(255, 255, 255, 0.2);
  --white3: rgba(255, 255, 255, 0.3);
  --white4: rgba(255, 255, 255, 0.4);
  --white5: rgba(255, 255, 255, 0.5);
  --white6: rgba(255, 255, 255, 0.6);
  --white7: rgba(255, 255, 255, 0.7);
  --white8: rgba(255, 255, 255, 0.8);
  --white9: rgba(255, 255, 255, 0.9);
  --yellowNVL: #fcb746;
  --purpleNVL: #5048e2;
  --zinc-50: #fafafa;
  --zinc-100: #f4f4f5;
  --zinc-200: #e4e4e7;
  --zinc-300: #d4d4d8;
  --zinc-400: #a1a1aa;
  --zinc-500: #71717a;
  --zinc-600: #52525b;
  --zinc-700: #3f3f46;
  --zinc-800: #27272a;
  --zinc-900: #18181b;
  --zinc-950: #09090b;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
}

button {
  cursor: pointer;
}

.box {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 100px;
}

@media only screen and (max-width: 950px) {
  .box {
    padding: 0 30px;
  }
}

.pricing-page {
  width: 100%;
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
}

.pricing-page .pricing-top {
  width: 100%;
  background-color: var(--zinc-950);
  padding: 50px 0 100px 0;
  position: relative;
  overflow: hidden;
}

.pricing-page .pricing-top::before {
  content: "";
  width: 1000px;
  height: 1000px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  transform: translate(-50%, 50%);
  filter: blur(100px);
  background: radial-gradient(rgba(252, 183, 70, 0.25), rgba(252, 183, 70, 0));
  z-index: 1;
}

.pricing-page .pricing-top::after {
  content: "";
  width: 1000px;
  height: 1000px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  transform: translate(50%, -50%);
  filter: blur(100px);
  background: radial-gradient(rgba(80, 72, 226, 0.5), rgba(80, 72, 226, 0));
  z-index: 1;
}

.pricing-page .pricing-top .pt-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 10;
}

.pricing-page .pricing-top .pt-box>img {
  width: -moz-max-content;
  width: max-content;
  height: 65px;
}

.pricing-page .pricing-top .pt-box h1 {
  font-size: 40px;
  font-weight: 500;
  color: var(--zinc-200);
  margin: 50px 0 10px 0;
  text-align: center;
  background: linear-gradient(100deg, var(--purpleNVL), #7f83e9, #a4aaf0);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 1040px) {
  .pricing-page .pricing-top .pt-box h1 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 850px) {
  .pricing-page .pricing-top .pt-box h1 {
    font-size: 28px;
  }
}

.pricing-page .pricing-top .pt-box h2 {
  font-size: 20px;
  font-weight: 400;
  color: var(--zinc-400);
}

@media only screen and (max-width: 850px) {
  .pricing-page .pricing-top .pt-box h2 {
    font-size: 17px;
    text-align: center;
  }
}

.pricing-page .pricing-top .pt-box .pt-price-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing-page .pricing-top .pt-box .pt-price-box .annual-monthly-box {
  width: -moz-max-content;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--zinc-800);
  border-radius: 5px;
  margin: 55px 0 135px 0;
}

.pricing-page .pricing-top .pt-box .pt-price-box .annual-monthly-box span {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: var(--zinc-400);
  cursor: pointer;
  border-radius: 5px;
  transition: 0.35s;
  position: relative;
}

.pricing-page .pricing-top .pt-box .pt-price-box .annual-monthly-box span i {
  display: flex;
  font-size: 10px;
  background-color: var(--white);
  padding: 2px 8px;
  white-space: nowrap;
  position: absolute;
  border-radius: 100px;
  font-style: normal;
  font-weight: 600;
  color: var(--purpleNVL);
  top: 0;
  left: 50%;
  transform: translate(-50%, -65%);
}

.pricing-page .pricing-top .pt-box .pt-price-box .annual-monthly-box span.active {
  background-color: var(--purpleNVL);
  transition: 0.35s;
  color: var(--white);
}

.pricing-page .pricing-top .pt-box .pt-price-box .annual-monthly-box span:hover {
  color: var(--white);
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid {
  width: 100%;
  grid-gap: 0;
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0;
}

@media only screen and (max-width: 850px) {
  .pricing-page .pricing-top .pt-box .pt-price-box .pt-grid {
    grid-template-columns: 1fr;
    grid-gap: 50px;
  }
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  background-color: hsla(0, 0%, 100%, 0.05);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  border: 1px solid var(--zinc-900);
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 5;
  padding: 65px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card button {
  margin: 50px 0 0 0;
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card .annual-off {
  width: -moz-max-content;
  width: max-content;
  padding: 5px 15px;
  font-size: 15px;
  background-color: var(--white);
  color: var(--purpleNVL);
  font-weight: 600;
  border-radius: 1000px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card h4 {
  font-size: 25px;
  font-weight: 600;
  color: var(--zinc-300);
  text-transform: uppercase;
  text-align: center;
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card p {
  font-size: 15px;
  color: var(--zinc-400);
  text-align: center;
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card p span {
  color: var(--zinc-500);
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card h3 {
  font-size: 50px;
  font-weight: 600;
  color: var(--zinc-200);
  text-transform: uppercase;
  text-align: center;
  margin: 50px 0 0 0;
}

@media only screen and (max-width: 1250px) {
  .pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card h3 {
    font-size: 36px;
  }
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card h3 span {
  font-size: 15px;
  font-weight: 400;
  color: var(--zinc-200);
}

@media only screen and (max-width: 1250px) {
  .pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card h3 span {
    font-size: 13px;
  }
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.starter {
  border-radius: 25px 0 0 25px;
}

@media only screen and (max-width: 850px) {
  .pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.starter {
    border-radius: 15px;
  }
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.pro {
  border-radius: 0 25px 25px 0;
}

@media only screen and (max-width: 850px) {
  .pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.pro {
    border-radius: 15px;
  }
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited {
  border-radius: 25px;
  padding: 0;
  background-color: var(--zinc-100);
  z-index: 6;
  height: -moz-max-content;
  height: max-content;
  transform: translate(0, -50px);
}

@media only screen and (max-width: 850px) {
  .pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited {
    transform: unset;
    border-radius: 15px;
  }
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited .unlimited-content {
  width: 100%;
  padding: 50px 35px 35px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited .unlimited-content>i {
  font-style: normal;
  font-size: 12px;
  color: var(--zinc-500);
  padding: 25px 0 0 0;
  display: flex;
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited h4 {
  color: var(--zinc-800);
  background: linear-gradient(100deg, var(--purpleNVL), #7f83e9, var(--yellowNVL));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited p {
  color: var(--zinc-700);
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited h3 {
  color: var(--zinc-700);
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited h3 span {
  color: var(--zinc-600);
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited h3 i {
  color: var(--zinc-400);
  font-size: 25px;
  font-weight: 500;
  font-style: normal;
  text-decoration: line-through;
}

@media only screen and (max-width: 1250px) {
  .pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited h3 i {
    font-size: 18px;
  }
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited button {
  margin: 50px 0 0 0;
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited img {
  position: absolute;
  width: 100px;
  top: -35.5px;
  right: -35.5px;
  rotate: 15deg;
}

@media only screen and (max-width: 850px) {
  .pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited img {
    right: -7.5px;
  }
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited .countdown {
  width: 100%;
  margin: 0;
  background-color: var(--zinc-800);
  padding: 35px 25px 25px 25px;
  border-radius: 25px 25px 0 0;
  background: linear-gradient(100deg, var(--purpleNVL), #7f83e9, var(--yellowNVL));
  text-align: center !important;
}

@media only screen and (max-width: 850px) {
  .pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited .countdown {
    border-radius: 15px;
    text-align: center !important;
  }
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited .countdown h6 {
  font-size: 13.5px;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--white);
  margin: 0 0 5px 0;
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited .countdown .countdown-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center !important;
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited .countdown .countdown-box>span {
  font-size: 25px;
  font-weight: 400;
  color: var(--white);
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited .countdown .countdown-box h5 {
  display: flex;
  align-items: baseline;
  justify-content: center;
  text-align: center;
  font-size: 22.5px;
  font-weight: 600;
  color: var(--white);
  margin: 0;
  text-align: center !important;
}

.pricing-page .pricing-top .pt-box .pt-price-box .pt-grid .pt-grid-card.unlimited .countdown .countdown-box h5 span {
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--white);
  margin: 0 0 0 5px;
}

.pricing-page .pricing-top .pt-box .features-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  padding: 75px 0 0 0;
  max-width: 1100px;
  margin: 0 auto;
}

@media only screen and (max-width: 1050px) {
  .pricing-page .pricing-top .pt-box .features-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .pricing-page .pricing-top .pt-box .features-grid {
    grid-template-columns: 1fr;
  }
}

.pricing-page .pricing-top .pt-box .features-grid .featured-card {
  width: 100%;
  padding: 20px 25px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.25);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.pricing-page .pricing-top .pt-box .features-grid .featured-card img {
  width: 35px;
  -o-object-fit: contain;
  object-fit: contain;
  margin: 0 10px 0 0;
}

.pricing-page .pricing-top .pt-box .features-grid .featured-card p {
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
}

.pricing-page .comparison-section {
  width: 100%;
  padding: 75px 0;
}

.pricing-page .comparison-section h1 {
  font-size: 40px;
  font-weight: 500;
  color: var(--zinc-200);
  margin: 0 0 50px 0;
  background: linear-gradient(100deg, var(--purpleNVL), #7f83e9, #a4aaf0);
  background-clip: text;
  -webkit-background-clip: text;
  text-align: center;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 1040px) {
  .pricing-page .comparison-section h1 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 850px) {
  .pricing-page .comparison-section h1 {
    font-size: 28px;
  }
}

.pricing-page .comparison-section .comparison-box {
  width: 100%;
  border-radius: 10px;
  overflow-x: hidden;
}

.pricing-page .comparison-section .comparison-box .comparison-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0;
}

@media only screen and (max-width: 850px) {
  .pricing-page .comparison-section .comparison-box .comparison-grid {
    grid-template-columns: 1fr;
    padding: 15px;
    background-color: var(--white);
  }
}

.pricing-page .comparison-section .comparison-box .comparison-grid .comparison-card {
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  color: var(--zinc-600);
  border-top: 1px solid var(--zinc-200);
}

.pricing-page .comparison-section .comparison-box .comparison-grid .comparison-card:first-child {
  font-weight: 500;
}

@media only screen and (max-width: 850px) {
  .pricing-page .comparison-section .comparison-box .comparison-grid .comparison-card:first-child {
    border: unset;
  }
}

.pricing-page .comparison-section .comparison-box .comparison-grid .comparison-card:last-child {
  background-color: var(--purpleNVL);
  color: var(--white);
  border: 1px solid var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 850px) {
  .pricing-page .comparison-section .comparison-box .comparison-grid .comparison-card:last-child {
    background-color: unset;
    color: var(--zinc-600);
    box-shadow: none;
    border-top: 1px solid var(--zinc-200);
  }
}

.pricing-page .comparison-section .comparison-box .comparison-grid .comparison-card span {
  display: none;
}

@media only screen and (max-width: 850px) {
  .pricing-page .comparison-section .comparison-box .comparison-grid .comparison-card span {
    display: block;
    font-weight: 600;
  }
}

.pricing-page .comparison-section .comparison-box .comparison-grid.comparison-head {
  background-color: var(--zinc-800);
}

@media only screen and (max-width: 850px) {
  .pricing-page .comparison-section .comparison-box .comparison-grid.comparison-head {
    display: none;
  }
}

.pricing-page .comparison-section .comparison-box .comparison-grid.comparison-head .comparison-card {
  color: var(--white);
}

.pricing-page .comparison-section .comparison-box .comparison-grid.comparison-head .comparison-card:last-child {
  border-left: 0;
}

.pricing-page .comparison-section .comparison-box .comparison-grid:first-child .comparison-card:last-child {
  border-radius: 0 10px 0 0;
}

.pricing-page .comparison-section .comparison-box:last-child:nth-child(2) button,
.pricing-page .comparison-section .comparison-box:last-child:nth-child(3) button {
  color: var(--purpleNVL);
}

.btn-outlined-purple {
  width: 100%;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid var(--purpleNVL);
  background-color: transparent;
  color: var(--zinc-300);
  outline: 0;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
}

.btn-grd {
  border: 0;
  color: var(--white);
  background: linear-gradient(100deg, var(--purpleNVL), #7f83e9, var(--yellowNVL));
}

.comparison-grid-unlimited .btn-unlimited {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
}

.comparison-grid-unlimited .btn-unlimited button {
  color: var(--white) !important;
}

/*# sourceMappingURL=Pricing.css.map */