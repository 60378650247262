.outer-contaier {
  box-shadow: 0 5px 15px rgb(8 15 52 / 10%);
  margin-top: 10px;
  background: #fff;
}
.header button.MuiButtonBase-root.MuiButton-root {
  padding: 0;
  width: 26px;
  height: 26px;
  min-width: 26px;
  border-radius: 50%;
  background: rgb(94 59 183 / 10%);
}
.header button.MuiButtonBase-root.MuiButton-root > svg {
  width: 16px;
}
ul.MuiList-root {
  border: 1px solid #ebecf3;
  border-radius: 12px;
  margin-bottom: 20px;
}
.outer-contaier > div {
  display: flex;
  align-items: center;
  margin-top: 16px;
  flex-direction: row-reverse;
  gap: 10px;
  max-width: 800px;
  margin: 20px auto 0;
}
.epr-skin-tones {
  display: 'none !important';
}
.outer-contaier > div .MuiTypography-h4 {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

.outer-contaier {
  box-shadow: 0 5px 15px rgb(8 15 52 / 10%);
  margin-top: 0;
  border-radius: 12px;
  min-height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.outer-contaier .form-contaier.edit-section-wraper {
  min-height: auto;
}
.MuiGrid-root.MuiGrid-item {
  justify-content: flex-start;
}

.MuiGrid-root.MuiGrid-item > .MuiBox-root {
  width: 100%;
}
.MuiOutlinedInput-input {
  padding: 10px !important;
  height: auto;
  font-family: 'DM Sans' !important;
  color: #3e3e3e !important;
}
.MuiDialog-container .MuiFormControl-root.MuiFormControl-fullWidth {
  border-radius: 10px;
  margin-top: 6px !important;
  margin-bottom: 16px !important;
}
.camp-main>.MuiBox-root {
  gap: 0;
}
.inner-child > .MuiBox-root {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 120px 120px 26px 108px;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
  position: relative;
}
.form-field-sm {
  width: 100%;
  max-width: 365px;
}
.form-contaier form {
  padding: 30px 30px 10px;
}
.footer-btn-group button {
  border-radius: 10px;
  align-items: center;
  display: flex;
  line-height: 1;
  padding: 14px 28px 12px;
  letter-spacing: 0;
}
.footer-btn-group button.MuiButton-colorSuccess {
  background: #1cc067 !important;
  border: none;
  outline: 3px solid rgb(28 192 103 / 30%);
}
.footer-btn-group button.MuiButton-colorPrimary {
  background: #2C73FF !important;
  border: none;
  outline: 3px solid rgb(94 59 183 / 30%);
}
.footer-btn-group button.MuiButton-colorSuccess:hover {
  background: #14a355 !important;
}
.footer-btn-group button.MuiButton-colorPrimary:hover {
  background: #442593 !important;
}
.inner-child .MuiTypography-body1 {
  background: rgb(44 115 255 / 16%);
  border-radius: 7px;
  padding: 11px;
  text-align: center;
  color: #2C73FF;
}
.inner-child button.MuiButton-outlined {
  border-radius: 7px;
  padding: 16px;
  display: flex;
}
.inner-child .MuiInputBase-colorPrimary {
  padding: 0;
  /* height: 40px; */
}
.inner-child .MuiInputBase-inputMultiline[aria-hidden="true"] {
  height: 0 !important;
  overflow: hidden !important;
}
fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #ebecf3 !important;
  border-radius: 7px;
}
.form-field-top .MuiInputBase-root {
  border-radius: 7px;
  background: #f6f6f6 !important;
}
.MuiInputLabel-formControl.MuiInputLabel-animated {
  top: -3px;
}
.inner-child .MuiTypography-body1:hover {
  background: rgb(28 93 205 / 100%);
  color: #fff;
}
.EmojiPickerReact {
  position: absolute !important;
  top: 100%;
  z-index: 1;
  /* right: 110px; */
}
.keyword-footer {
  background: #e0eafb;
  display: flex;
  width: calc(100% + 48px) !important;
  margin: 0 -24px;
  padding: 10px 24px;
  border-radius: 0 0 6px 6px;
}
ul.MuiList-root.ctm-listing-keywords {
  margin-bottom: 16px;
}
.keyword-footer button {
  width: max-content;
  border-radius: 8px;
  outline: 3px solid rgb(44 115 255 / 15%);
  padding: 5px 24px;
}
.keywords-sec-only .msg-label.msg-label-space {
  padding-left: 0;
  margin: 0;
}
.keyword-group-new .MuiToggleButtonGroup-grouped {
  min-width: 56px;
}
.keyword-group-new .MuiToggleButtonGroup-grouped {
  min-width: 56px;
  padding: 5px 16px;
}
.key-form-group p.msg-label {
  margin-bottom: 4px !important;
  margin-top: 5px !important;
  color: #475259;
}
.keywords-sec-only .MuiOutlinedInput-input {
  padding: 12px !important;
  background: #F6F6F6;
  color: #989898;
}
.key-form-group-btm .MuiBox-root {
  border-radius: 6px;
}
.key-form-tags>.MuiBox-root>.MuiBox-root {
    background: #F6F6F6 !important;
    border: 1px solid #EBECF3 !important;
}

.key-form-tags .MuiButtonBase-root.MuiChip-root {
    background: #1CC067;
    color: #fff;
    border: 1px solid #1CC067;
    height: 28px;
}

.key-form-tags .MuiButtonBase-root.MuiChip-root svg.MuiSvgIcon-root {
    color: #fff;
    width: 16px;
}
.keywords-sec-only .key-form-group.key-form-tags>div {
  background: #f6f6f6;
  padding: 10px 16px 16px;
  border: 1px solid #ebecf3;
  border-radius: 7px;
}
.keywords-sec-only .key-form-tags>.MuiBox-root>.MuiBox-root {
  background: #F6F6F6 !important;
  
  border: 0px solid #EBECF3 !important;
  padding: 0;
  margin-bottom: 10px;
  margin-top: 0px;
}

.keywords-sec-only .key-form-group.key-form-tags>div input {
  background: #fff;
}

.message-container-create-new .message-wraper .MuiGrid-container .MuiGrid-item {
  flex-basis: 100%;
  width: 100%;
  max-width: 100% !important;
  border: none;
  background: transparent;
  border-radius: 0 0 10px 10px;
}
.message-container-create-new ul.MuiList-root.message-wraper {
  margin-bottom: 16px;
  padding-top: 0;
}
.message-container-create-new ul.MuiList-root.message-wraper p.msg-label {
  margin-top: 20px;
}
.message-container-create-new ul.MuiList-root.message-wraper p.msg-label + div .MuiToggleButtonGroup-root {
  background: #f6f6f6 !important;
  padding: 10px;
  width: calc(100% - 30px);
  margin: 0 auto;
}
.message-container-create-new .message-wraper .MuiGrid-container {
  gap: 0px;
}

.message-container-create-new {margin: 16px 0px;}
.new-section-designing-inner {
  margin-top: 36px !important;
}
.outer-contaier {
  margin-top: 20px;
  margin-bottom: 20px;
}
.new-section-designing {
  padding: 0 !important;
}
.form-contaier.edit-section-wraper {
  margin-bottom: 20px;
}
.new-section-designing-inner {
  position: relative;
}
.new-section-designing-inner>div {
  margin: 10px 0 !important;
  position: relative;
  border-top: 1px solid #EBECF3;
  border-bottom: 1px solid #EBECF3;
  border-right: 1px solid #EBECF3;
  background: #fff;
}
.edit-section-wraper ul.MuiList-root {
  border: none;
}
.form-contaier .new-section-designing .footer-btn-group {
  width: 100% !important;
  margin: 0 !important;
  background: transparent !important;
  padding: 10px 0 0;
}
.new-section-designing h5 {
  font-size: 14px;
}
.new-section-designing-inner button.MuiButtonBase-root {
  border-radius: 8px;
  letter-spacing: 0;
}
.new-section-designing-inner {
  position: relative;
}
.new-section-designing-inner .MuiIconButton-colorSecondary.MuiIconButton-sizeMedium {
  position: absolute;
  right: 16px;
  bottom: 16px;
  background: #FF6666;
  color: #fff !important;
  border-radius: 50%;
}
.emoji-icons {
  padding: 0;
}
.emoji-icons svg {
  width: 25px;
  height: 25px;
}
.form-contaier.edit-section-wraper {
  margin-bottom: 20px;
  min-height: calc(100vh - 100px);
  align-items: flex-start;
}
.create-section-wraper-new .new-section-designing .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12 {
  padding-top: 0;
}
.create-section-wraper-new ul.MuiList-root .MuiGrid-root {
  padding: 0 !important;
}
.form-contaier.create-section-wraper-new .footer-btn-group {
  width: 100%;
  border-radius: 0 0 10px 10px;
  background-color: transparent !important;
  margin: 0 -30px !important;
  width: calc(100% + 48px);
}
.keywords-sec-only .MuiOutlinedInput-input {
  border-radius: 7px ;
}